import { useCallback } from "react";

export const Filter = ({ filter }) => {
  const { className, label, onFilter, ratio, value } = filter
  const handleFilter= useCallback(() => {
    return (event) => {
      event.preventDefault();

      if(onFilter) onFilter()
    }
  }, [onFilter])

  return (
    <div
      className={`card-item p1 ${className}`.trim()}
      onClick={handleFilter({ onFilter })}
    >
      <div className="f g1 f-column">
        <div className="f g1 f-between">
          <span className="card-title">{label}</span>
          <span className="card-percent">({ratio}%)</span>
        </div>
        <span className="card-value">{value}</span>
      </div>
    </div>
  )
}
