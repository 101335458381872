import { useCallback, useContext, useMemo } from "react";

import { AppContext } from "../../../App";
import { Chat, Container, Span, Table } from "../../../c";
import { formatMoney } from "../../../f";
import { DropdownEditMenu } from "./DropdownEditMenu";

import SITUATIONS from "./contents/label_situations.json";
import { AuditIconColumn } from "../TravelRecords/columns/AuditIconColumn";
import { getColumns } from "../TravelRecords/useCases/getColumns";
import { transformToRow } from "../TravelRecords/useCases/transformToRow";


const travelRecordsColumns = [
  "ticketId",
  "productGropup",
  "scheduleOn",
  "vehicle",
  'taxDocument',
  'operation',
  'service',
  "mainAmount",
  "additionalAmount",
];

export const FaturamentoCard = ({
  faturamento,
  setObservacao,
  onFetchRecords,
}) => {
  const App = useContext(AppContext);
  const lang = { ...App.lang.global };
  const icons = App.icons;

  const formatDate = useCallback(
    (date) => {
      return App.moment(date).format(lang.formatoData);
    },
    [App, lang.formatoData]
  );

  const columns = useMemo(() => {
    const travelColumns = getColumns({ user: App.user })

    return travelRecordsColumns.map((column) => {
      return travelColumns.find(([,travelColumn]) => column === travelColumn)
    }).filter((column) => column)
  }, [App.user])

  const travelRecords = useMemo(() => {
    return faturamento.portarias.map((travelRecord) => {
      return transformToRow({ // rows
        columns,
        lang: App.lang.freight_controllership.travel_records,
        onFetchRecords,
        user: App.user,
        onToggle: () => {},
        record: travelRecord,
      })
    })
  }, [App.lang.freight_controllership.travel_records, App.user, columns, faturamento.portarias, onFetchRecords])

  return (
    <div className="invoicing-card">
      <div className="header" style={{ margin: "-10px -10px 0" }}>
        <div
          className="f g2 w100 header-content"
          style={{
            backgroundColor: "var(--colorTitle)",
            padding: "12px 0",
          }}
        >
          <div className="f w100">
            <div
              className="f f-column w100 g2"
              style={{ paddingLeft: "10px", flex: "0 0 20%" }}
            >
              <div className="f f-column g1">
                <span className="title">RF</span>
                <span className="featured-content">{faturamento.ID_UOCC}</span>
              </div>
              <div className="f f-column g1">
                <span className="title">Referência</span>
                <span className="content">{faturamento.NR_DCP}</span>
              </div>
            </div>

            <div className="f f-column w100 g2" style={{ flex: "1" }}>
              <div className="f f-column g1 w100">
                <span className="title">Transportador</span>
                <span className="featured-content w100">
                  {faturamento.NM_TRANSP}
                </span>
              </div>
              <div className="f f-column g1">
                <span className="title">Criado em</span>
                <span className="content">
                  {formatDate(faturamento.DT_DCP)}
                </span>
              </div>
            </div>

            <div className="f f-column w100 g2" style={{ flex: "1" }}>
              <div className="f f-column g1">
                <span className="title">Valor total</span>
                <span className="featured-content">
                  {formatMoney(faturamento.VL_DCP, 2)}
                </span>
              </div>
              <div className="f f-column g1">
                <span className="title">Viagens</span>
                <span className="content">{travelRecords.length}</span>
              </div>
            </div>

          </div>
          <div
            className="status"
            style={{
              backgroundColor: SITUATIONS[faturamento.FAT_STS].color,
              position: "absolute",
              right: "0",
              top: "0",
            }}
          >
            <Span
              style={{ padding: "0" }}
              value={
                <span className="f g1 center-v center-h">
                  {SITUATIONS[faturamento.FAT_STS].label ?? "Sem status"}
                  {faturamento.CD_IDC === "2" ? (
                    <icons.MdEmail size={24} />
                  ) : faturamento.CD_IDC === "4" ? (
                    <icons.MdMarkEmailRead size={24} />
                  ) : null}
                  <DropdownEditMenu
                    faturamento={faturamento}
                    setObservacao={setObservacao}
                    onFinish={onFetchRecords}
                    control={<icons.BsThreeDotsVertical size={18} />}
                  />
                </span>
              }
              className="center"
            />
          </div>
        </div>
      </div>
      <div className="infos"></div>
      <Container>
        <Table
          isSub={false}
          hasSub={"SUB"}
          pagination="no-pagination"
          data={travelRecords}
          columns={columns}
        />
      </Container>
      <Chat user={App.user.id_usr} msg={faturamento.OBSS} />
    </div>
  );
};
