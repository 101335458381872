import { TravelRecordPresenter } from "../../presenters/TravelRecordPresenter";

export const getDetails = (record) => {
  const presenter = new TravelRecordPresenter(record)

  return (
    [
      [
        'group-1',
        {minWidth: '24%'} ,
        [
          {
            label: 'Parceiro',
            value: presenter.valueOf('commercialPartner')
          },
          {
            label: 'Transportador',
            value: presenter.valueOf('shippingCompany')
          },
          {
            label: 'Tomador',
            value: presenter.valueOf('taker')
          }
        ],
      ], [
        'group-2',
        {minWidth: '24%'},
        [
          {
            label: 'Contrato',
            value: presenter.valueOf('contractId')
          },
          {
            label: 'Produto',
            value: presenter.valueOf('product')
          },
          {
            label: 'Área de Negócio',
            value: presenter.valueOf('businessArea')
          },
        ],
      ],
      [
        'group-3',
        {minWidth: '24%'},
        [
          {
            label: 'Prazo',
            value: presenter.valueOf('deadline'),
          },
          {
            label: 'Itinerário',
            value: presenter.valueOf('itineraryId'),
          }
        ]
      ],
      [
        'group-4',
        {minWidth: '24%'},
        [
          {
            label: 'Peso NF-e',
            value: presenter.valueOf('nfeWeight')
          },
          {
            label: 'Peso ticket',
            value: presenter.valueOf('ticketWeight')
          },
          {
            label: 'Peso quebra',
            value: presenter.valueOf('breakageWeight')
          }
        ]
      ],
    ]
  )
}
