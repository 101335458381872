import { useState, createContext, useEffect } from "react"
import pj from '../package.json'

import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as iconsBs from 'react-icons/bs'
import * as iconsFa from 'react-icons/fa'
import * as iconsMd from 'react-icons/md'
import * as iconsGi from 'react-icons/gi'
import * as iconsIo5 from 'react-icons/io5'
import * as iconsVelog from './a/iconsVelog'
import * as iconsHi2 from "react-icons/hi2";

import './s/index.sass'

import * as p from './p/'
import * as c from './c/'
import * as f from './f'
import langs from './langs.json'

import Logo from './a/logo.svg'
import { ReactSVG } from "react-svg"

export const AppContext = createContext()

const getEnvironment = (env) => {
  if(env === 0) return '' // ambiente de produção não exibe tag de ambiente

  return [
    'Production',        // 0
    'Pre-deploy',        // 1
    'Homologation',      // 2
    'Quality Assurance', // 3
    'Testing',           // 4
    'Dev - Local',       // 5
  ][env] ?? 'Dev - Local'
}

function Application({ env = 0 }){
  const [confirm, setConfirm] = useState({})
  const [language, setLanguage] = useState({})

  const { location: { protocol, hostname: host, pathname} } = window
  const path = pathname.split('/')[1]
  const baseURL = `${protocol}//${host}${(path ? `/${path}` : '')}/`

  const [app, setApp] = useState({
      env,
      loading: null,
      LoadingComponent: Loading,
      cliente: false,
      notifications: {itens:[], noreads: 0},
      pj, protocol, host, baseURL,
      confirm: (text='', onConfirm = () => {}, title='', onCancel = () => {}) => {
        setConfirm({ show: true, text, title, onConfirm, onCancel })
      },
      prefs: {lang:0},
      components: {},
      diretivas: {},
    })

  function Loading({msg}){
    return <div className="vertti-logo-loader f center p10 w100 g2 f-column">
      {!!msg && <b>{msg}</b>}
      <ReactSVG src={Logo} />
    </div>
  }

  function api( action, json = {}, quiet = false ){
    const base = app.baseURL + 'api/'

    return fetch( base, {
      method: 'POST',
      headers: {'content-type': 'text/plain'},
      body: JSON.stringify({action: action, ...json,
        token: sessionStorage.getItem('token')??'e30=',
        version: app.pj.version
      })
    })
    .then(r => r.json()).then(r => {
      sessionStorage.setItem('token', r?.token??'e30=')

      if( !r.status && !r.command ){
        toast.error( lang?.errors[r.error.message] ?? r.error.message )
      }
      if( r.command === 'logout' ) logout()

      return r
    })
  }

  const getTranslation = (obj, langIndex) => {
    const translate = (item) => {
      if (Array.isArray(item)) {
        return item[langIndex];
      } else if (typeof item === 'object' && item !== null) {
        return Object.fromEntries(Object.entries(item).map(([key, val]) => [key, translate(val)]));
      }
      return item;
    };

    return translate(obj);
  };

  const lang = getTranslation(langs, app.prefs.lang ?? 0)

  function logout(){
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('diretivas')
    setApp({...app, user: {}, prefs: null, components: {}, diretivas: {},})
    window.location.reload(true)
    toast.error(lang.errors.usuario_desconectado)
  }

  document.documentElement.lang = app.prefs?.lang!==null ? lang?.global?.document_langs[app.prefs?.lang] : navigator.language
  if(app.prefs?.dark) document.body.classList.add('dark'); else document.body.classList.remove('dark')

  function setPrefs(prefs, save=true){
    const newPrefs = {...app.prefs, ...prefs}
    setApp({...app, prefs: newPrefs})

    if(save) {
      api('users::savePrefs', {prefs: newPrefs})
      setLanguage({newPrefs})
    }
  }

  useEffect(() => {
    !!language.newPrefs && toast.success(lang.global.prefs_saved_success)
  }, [language])

  useEffect(() => {
    (async () => {
      const [cliente] = await Promise.all([
        api('users::getCliente')
      ])

      setApp({ ...app,
        cliente: (!cliente.results?.id || cliente.error?.message === 'invalid_client')
                    ? -1 : cliente.results,
        user: JSON.parse(atob(sessionStorage.getItem('token')??'e30=')),
      })
    })()
  }, [])

  const environment = getEnvironment(env)

  const appProvider = { ...app, setApp, prefs: app.prefs??null,
    logout, lang, api, moment, toast, f, environment, setPrefs,
    icons: {...iconsBs, ...iconsFa, ...iconsMd, ...iconsGi, ...iconsIo5, ...iconsHi2, ...iconsVelog}
  }

  return (<>
    <AppContext.Provider value={appProvider}>
      {environment && (
        <span className='version-env-big-stamp destaque danger'>
          {environment}
        </span>
      )}
      {!app.cliente
        ? <div id='lockerScreen' style={{display:'flex'}}>{Loading({})}</div>
          //<c.LockerScreen />
        : (app.cliente === -1
          ? lang.errors.invalid_client
          : ( !app.user.id_usr
              ? <p.Login />
              : <p.Layout />
            )
        )
      }

      {confirm.show && <ConfirmBox confirm={confirm} onClose={()=>setConfirm({})} />}

      <ToastContainer position="top-center" theme={app.prefs?.dark?'dark':'light'} />
    </AppContext.Provider>
  </>)
}

function ConfirmBox({confirm, onClose}){
  const [loading, setLoading] = useState(false)

  function submit(){
    setLoading(true)
    const f = confirm.onConfirm()

    if( f instanceof Promise ){
      return f.then(r => {
        setLoading(false)
        return r?.results ?? r
      })
    }else{
      return f
    }
  }

  function onCancel(){
    setLoading(true)
    const f = confirm.onCancel()

    if( f instanceof Promise ){
      return f.then(r => {
        setLoading(false)
        return r?.results ?? r
      })
    }else{
      return f
    }
  }

  return(
    <c.Modal title={confirm.title} onFinish={submit} loading={loading} onCancel={onCancel}
      onClose={onClose} cancelText='Não' okText="Sim" successMsg=''
    >{confirm.text}</c.Modal>
  )
}

export function log( data='' ){console.log(data)}

export default Application
