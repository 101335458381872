import { DaterangePickerFilter } from './fields/DaterangePickerFilter'
import { InputFilter } from './fields/InputFilter'
import { SelectFilter } from './fields/SelectFilter'

export const FieldFilter = ({ filter, onChange }) => {
  const { kind, label, options, settings, value } = filter

  switch (kind) {
    case 'daterange':
      return (
        <DaterangePickerFilter
          label={label}
          onChange={onChange}
          settings={settings}
          value={value}
        />
      );
    case 'input':
      return (
        <InputFilter
          label={label}
          onChange={onChange}
          settings={settings}
          value={value}
        />
      );
    case 'select':
    case 'multi-select':
      return (
        <SelectFilter
          kind={kind}
          label={label}
          onChange={onChange}
          options={options}
          settings={settings}
          value={value}
        />
      );
    default:
      return null;
  }
};
