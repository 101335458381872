import React from 'react';

import './index.sass';


export const Badge = ({text, color, size = 'md'}) => {
  return(
    <div className="badge-container" >
      <span className={`badge-pill ${color} ${size} nowrap`} >
        {text}
      </span>
    </div>


  );
};
