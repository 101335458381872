import { useState, useEffect, useContext, useRef,useMemo } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import debounce from 'lodash.debounce';

export default function Graficos({dados}) {
    const App = useContext(AppContext),
        lang = { ...App.lang.gerencialQualidade, ...App.lang.global },
        icons = App.icons;
    const divRef = useRef();

    const minWidth = 1240;
    const [size, setSize] = useState({ width: 0, height: 0 });
    const divStyle = size.width < minWidth ? { gap: "10px" } : { display: 'flex', gap: "10px" };
    const barGraphStyle = size.width < minWidth ? { width: '100%', maxWidth: '100%' } : { width: '25%', maxWidth: '50%' } ;
    const lineGraphStyle = size.width < minWidth ? { width: '100%', maxWidth: '100%' } : { width: '50%', maxWidth: '50%' } ;
    const composedGraphStyle = size.width < minWidth ? { width: '100%', maxWidth: '100%' } : { width: '25%', maxWidth: '50%' } ;

    const barGraphHeight = size.width < minWidth ? 200 : 300;
    const lineGraphHeight = size.width < minWidth ? 200 : 300 ;
    const composedGraphHeight = size.width < minWidth ? 200 : 300;

    const dataMonth = useMemo(() =>{
        return dados?.MES ?? []
    },[dados])

    const dataDays = useMemo(()=>{
        return dados?.DIA ?? []
    },[dados])

    const dataAderencia = useMemo(()=>{
        return dados?.ADERENCIA ?? []
    },[dados])

    useEffect(() => {
        const updateSize = debounce((entries) => {
            for (let entry of entries) {
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                });
            }
        }, 500); // Adjust the debounce time as needed

        const resizeObserver = new ResizeObserver(updateSize);

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <>
            <div className="frame f f-column card">
                <div className="frame-content" ref={divRef} >
                    <div style={divStyle}>
                        <div style={barGraphStyle}>
                            <c.Frame title={'Volume recepção x expedição (mês)'} autoScroll={false}>
                                <c.chart.ResponsiveContainer width="100%" minWidth={200} height={barGraphHeight}>
                                    <c.chart.BarChart
                                        data={dataMonth}
                                        barSize={20}
                                    >
                                        <c.chart.CartesianGrid vertical={false} />
                                        <c.chart.XAxis dataKey="MONTH" />
                                        <c.chart.YAxis />
                                        <c.chart.Tooltip />
                                        <c.chart.Bar dataKey="VL_EXP" fill="#00c1de" radius={[4, 4, 0, 0]} />
                                        <c.chart.Bar dataKey="VL_RCP" fill="#00bca0" radius={[4, 4, 0, 0]} />
                                    </c.chart.BarChart>
                                </c.chart.ResponsiveContainer>
                            </c.Frame>
                        </div>
                        <div style={lineGraphStyle}>
                            <c.Frame title={'Volume recepção x expedição (dia) Ritmo'} autoScroll={false} >
                                <c.chart.ResponsiveContainer width="100%" minWidth={200} height={lineGraphHeight}>
                                    <c.chart.LineChart
                                        data={dataDays}

                                    >
                                        <c.chart.CartesianGrid strokeDasharray="3 3" />
                                        <c.chart.XAxis dataKey="date" />
                                        <c.chart.YAxis />
                                        <c.chart.Tooltip />
                                        <c.chart.Line type="monotone" dataKey="VL_EXP" stroke="#00c1de" />
                                        <c.chart.Line type="monotone" dataKey="VL_RCP" stroke="#00bca0" />
                                    </c.chart.LineChart>
                                </c.chart.ResponsiveContainer>
                            </c.Frame>
                        </div>
                        <div style={composedGraphStyle}>
                            <c.Frame title={'Aderencia'} autoScroll={false}>
                                <c.chart.ResponsiveContainer width="100%" minWidth={200} height={composedGraphHeight}>
                                    <c.chart.ComposedChart data={dataAderencia}>
                                        <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <c.chart.Tooltip /><c.chart.YAxis /><c.chart.XAxis dataKey="Data" />
                                        <c.chart.Area type="natural" dataKey="Cota" stroke="var(--info)" fill="var(--info)" strokeWidth={4} />
                                        <c.chart.Bar barSize={5} dataKey="Consumida" stackId="consumida" fill="var(--success)" />
                                    </c.chart.ComposedChart>
                                </c.chart.ResponsiveContainer>
                            </c.Frame>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )

}
