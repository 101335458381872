import { translate_with_options } from "../../../../f"

export const processInvoicesInBatch = async (
  { data, errors },
  { api, lang, onFetchRecords, toast }
) => {
  if (errors.length > 0 || data[0].length !== 4 ) {
    toast.warning(lang.errors.invalid_document)
    return
  }

  const invoices = data.reduce((group, [cte, ticket, situation, invoiceNumber], line) => {
    if (situation.toUpperCase() === 'PAGO') {
      group[invoiceNumber] ||= []
      group[invoiceNumber].push({ cte, ticket })
    }

    return group
  }, {})

  if (Object.keys(invoices).length === 0){
    toast.warning(lang.errors.invoices_not_fount)
    return
  }

  const action = 'Controllership::create_invoices_in_batch'
  const { status, results } = await api(action, { invoices })

  if( !status ){
    toast.warning(lang.errors.no_import_invoices)
    return
  }

  const { errors: validations = [] } = results

  if( validations.length > 0 ) {
    toast.warning()
    toast.error(
      <div>
        {lang.errors.no_import_invoices_details}
        <ul>
          {validations.map(({ error, ...options}) => {
            const message = translate_with_options(lang.errors, error, options)
            console.log({ message })
            return <li key={error}>{message}</li>
          })}
        </ul>
      </div>
    )
    return
  }

  toast.success(lang.success)
  onFetchRecords()
}
