import React from 'react'

import { Card } from './Card'

export const CardFilters = ({ filters, visible = false }) => {
  if(!visible) return <></>

  return (
    <div className="f g2 f-wrap">
      {filters.map(({ className, key, filters, label }) => (
        <Card className={className} key={key} filters={filters} label={label} />
      ))}
    </div>
  )
}
