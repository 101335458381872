import * as p from './p/'
import { DistribuirCotas } from './p/distribuir/DistribuirCotas';
import { FreightControllership } from './p/FreigthControllership';

function Sidebar(App){
  if( !App?.user || !App?.components || !App?.lang ) return []

  const u = App?.user;
  const menus = [
    {
      title: App.lang.menu.dashboard,
      icon: App.icons.BsFillGrid1X2Fill,
      page: null,
      allow: (u.in_uorgadm==='T' && u.in_mon!=='T' && u.tp_dashund==='2') || u.in_clt==='T',
      itens: [
        {
          title: App.lang.menu.dashDescarga,
          icon: App.icons.BsFillArrowDownRightSquareFill,
          page: p.DashDescarga,
          hasReloader: true,
          allow: (u.in_uorg === 'T' || u.in_clt==='T') && App.components.dash_descarga
        },
        {
          title: App.lang.menu.dashCarga,
          icon: App.icons.BsFillArrowUpLeftSquareFill,
          page: p.DashCarga,
          hasReloader: true,
          allow: (u.in_uorg === 'T' || u.in_clt==='T') && App.components.dash_carga,
        }
      ]
    },
    {
      title: App.lang.menu.dashboard,
      icon: App.icons.BsFillGrid1X2Fill,
      page: null,
      allow: (u.in_uorgadm==='T' && u.in_mon!=='T' && u.tp_dashund!=='2')||u.in_trp==='T' ,
      itens: [
        {
          title: App.lang.menu.geral,
          icon: App.icons.MdViewComfy,
          page: p.DashboardGeral,
          hasReloader: true,
          allow: !!App.components.dash_geral,
        }
      ]
    },
    {
      title: App.lang.menu.gerencial,
      icon: App.icons.MdOutlineShowChart,
      page: null,
      allow: (u.in_uorg==='T' && u.in_mon!=='T') || u.in_clt==='T',
      itens: [
        {
          title: App.lang.menu.operacao,
          icon: null,
          page: p.GerencialOperacao,
          allow: !!App.components.gerencial_operacao,
        },
        {
          title: App.lang.menu.qualidade,
          icon: null  ,
          page: p.GerecialQualidade,
          allow: !!App.components.gerencial_qualidade && (App.user.in_uorgadm === "T" || App.user.in_trd === "T"),
        },
        {
          title: App.lang.menu.fracionada,
          icon: null,
          page: p.GerencialFracionada,
          allow: !!App.components.gerencial_fracionada && App.user.in_uorgadm === "T"
        },
        {
          title: App.lang.menu.carregamento_ferro,
          icon: null,
          page: p.GerencialCarregamentoFerro,
          allow: !!App.components.gerencial_ferro && App.user.in_uorgadm === "T",
        }
      ]
    },
    {
      title: App.lang.menu.agendamentos,
      icon: App.icons.BsCalendar,
      page: null,
      allow: u.in_trp==='T',
      itens: [
        {
          title: App.lang.menu.agendar,
          icon: App.icons.BsCalendarPlus,
          page: p.TransportadorAgendar,
          allow: !!App.components.agendar,
          hasReloader: true,
        },
        {
          title: App.lang.menu.agendado,
          icon: App.icons.BsCalendarCheck,
          page: p.TransportadorAgendados,
          allow: !!App.components.agendado,
          hasReloader: true,
        },
      ]
    },
    {
      title: App.lang.menu.distribuir,
      icon: App.icons.MdOpenWith,
      page: null,
      allow: u.in_clt==='T' || (u.in_uorgadm==='T' && u.in_mon!=='T') || !!App.components.distribuicao_lote,
      itens: [
        {
          title: App.lang.menu.distribuicaoFOB,
          icon: null,
          page: p.DistribuicaoFOB,
          allow: (u.in_uorgadm==='T' && u.in_mon!=='T') && App.components.distribuicao_fob,
          hasReloader: true,
        },
        {
          title: App.lang.menu.distribuirCotas,
          icon: null,
          page: DistribuirCotas,
          allow: u.in_clt==='T' && App.components.distribuicao_cotas,
          hasReloader: true,
        },
        {
          title: App.lang.menu.distribuirLote,
          icon: null,
          page: p.DistribuirLote,
          allow: u.in_uorgadm==='T',// || !!App.components.distribuicao_lote,
          hasReloader: true,
        },
      ]
    },
    {
      title: App.lang.menu.monitor,
      icon: App.icons.MdOutlineShowChart,
      page: p.Monitor,
      allow: (u.in_uorg==='T' || (u.in_ebc==='T' && u.in_mon==='T')) && App.components.monitor,
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.contratos,
      icon: App.icons.MdBusiness,
      page: p.Contratos,
      allow: u.in_uorgadm==='T' && App.components.contrato,
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.cadastros,
      icon: App.icons.MdMenuBook,
      page: null,
      allow: true,
      itens: [
        {
          title: App.lang.menu.seguros,
          icon: App.icons.BsShieldExclamation,
          page: p.Seguros,
          allow: u.in_sgr==='T' && App.components.cad_seguro,
          hasReloader: true,
        },
        {
          title: App.lang.menu.produtos,
          icon: App.icons.MdProductionQuantityLimits,
          page: p.Produtos,
          allow: u.in_uorgadm==='T' && App.components.cad_produto,
          hasReloader: true,
        },
        {
          title: App.lang.menu.transportadoras,
          icon: App.icons.BsTruck,
          page: p.Transportadoras,
          allow: u.in_clt==='T' && App.components.cad_transportadora,
          hasReloader: true,
        },
        {
          title: App.lang.menu.origem_destino,
          icon: App.icons.MdCompareArrows,
          page: p.OrigensDestinos,
          allow: u.in_clt==='T' && App.components.cad_origem_destino,
          hasReloader: true,
        },
        {
          title: App.lang.menu.turnos,
          icon: App.icons.BsFillClockFill,
          page: p.Turnos,
          allow: (App.env !== 0 && u.in_uorgadm==='T') && App.components.cad_turno,
          hasReloader: true,
        },
        {
          title: App.lang.menu.Natop,
          icon: App.icons.BsFillFileTextFill,
          page: p.Natop,
          allow: (App.env !== 0 && u.in_uorgadm==='T') && App.components.cad_natop,
          hasReloader: true,
        }
      ]
    },
    {
      title: App.lang.menu.pedidos,
      icon: App.icons.MdProductionQuantityLimits,
      page: p.Pedidos,
      allow: (u.in_uorgadm === 'T' || u.in_clt === 'T' || u.in_crt === 'T') && App.components.pedido,
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.ordem_carregamento,
      icon: App.icons.FaTruckLoading,
      page: p.OrdemCarregamento,
      allow: u.in_uorgadm === 'T' && App.components.ordem_carregamento,
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.estoques,
      icon: App.icons.BsBoxSeamFill,
      page: p.Estoques,
      allow: u.in_uorgadm === 'T' && App.components.estoque,
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.frete,
      icon: App.icons.FaTruck,
      page: null,
      allow: u.in_rf === 'T' || u.in_trp === 'T' || u.in_uorgadm === 'T',
      itens: [
        {
          title: App.lang.menu.frete_resumo,
          icon: null,
          page: p.FreteResumo,
          //allow: true,
          allow: u.in_rf === 'T' && App.components.frete_resumo,
          hasReloader: true,
        },
        {
          title: App.lang.menu.frete_fechamento,
          icon: null,
          page: p.FreteFechamento,
          allow:  u.in_trp === 'T' && App.components.frete_fechamento,
          hasReloader: true,
        },
      ]
    },
    {
      title: App.lang.menu.controllership,
      icon: App.icons.FaTruck,
      page: null,
      allow: u.in_rf === 'T' || u.in_trp === 'T' || u.in_uorgadm === 'T',
      itens: [
        {
          title: App.lang.menu.freight,
          icon: null,
          page: FreightControllership,
          allow: (u.in_uorgadm === 'T' || u.in_trp === 'T') && App.components.frete_auditoria,
          hasReloader: true,
        },
      ]
    },
    {
      title: App.lang.menu.integracaoAppa,
      icon: App.icons.FaShip,
      page: p.IntegracaoAppa,
      allow: u.in_uorgadm === 'T',
      hasReloader: true,
      itens: []
    },
    {
      title: App.lang.menu.itinerarios,
      icon: App.icons.BsFillMapFill,
      page: null,
      //allow: u.in_uorgadm === 'T',
      allow: true,
      hasReloader: false,
      itens: [
        {
          title: App.lang.menu.itinerarios,
          icon: null,
          page: p.Itinerarios,
          allow: u.in_uorgadm === 'T' && App.components.itinerarios,
          hasReloader: true,
        },
        {
          title: App.lang.menu.rotas,
          icon: null,
          page: p.Rotas,
          allow: u.in_uorgadm === 'T' && App.components.rotas,
          hasReloader: true,
        },
      ]
    },
    {
      title: 'Painel Cliente',
      icon: App.icons.BsGraphUp ,
      page: p.PortalCliente,
      allow: (u.in_uorg === 'T' || u.in_clt==='T') && App.components.painel_cliente,
      hasReloader: true,
      itens: []
    },
  ]

  return menus
    .filter((menu) => {
      if(!menu.allow) return false

      if(!!menu.page) return true

      return menu.itens.some(({ allow }) => allow)
    })
    .map((menu)=> ({
      ...menu,
      itens: menu.itens.filter(({ allow }) => allow)
    }))
}

export default Sidebar
