import { useState, useEffect, useContext,useMemo } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

export default function TablePortalCliente({dados}) {
    const App = useContext(AppContext),
        lang = { ...App.lang.gerencialQualidade, ...App.lang.global },
        icons = App.icons

    const [loading, setLoading] = useState(false)
    const colunas = useMemo(() =>{
        return [
            ['ID_RV', 'ID_UOCC'],
            ['Data', 'DT_PRV'], // PRT_APR
            ['Placa', 'ID_VCL'],// PRT_APR
            ['Modal', 'MODAL'], // VER NA RUMO
            ['Operacão', 'OPERACAO'], // PRT_APR
            ['Situação', 'CD_STS'], // PRT_APR
            ['Contrato', 'ID_CN'], // PRT_APR OU OPR_CN
            ['Produto', 'NM_PRORED'], // PRT_APR -> PRT_MTRB -> ...
            ['Transportador', 'NM_PES'], // PRT_APR -> PRC_PESBSC
            ['Classificador', 'CLASSIFICADOR'], // NM_PES FROM SEG_USR
            ['Peso líquido', 'QT_ORG'], // QUALIDADE
            ['UMI', 'UMI'], // QUALIDADE
            ['IMP', 'IMP'], // QUALIDADE
            ['AVA', 'AVA'], // QUALIDADE
            ['QBD', 'QBD'], // QUALIDADE
            ['ESV', 'ESV']  // QUALIDADE
        ]
    },[])

    return (
        <c.Frame flex >
            <div style={{maxWidth:'100%',width:'100%'}}>
                <c.Table loading={loading}
                    columns={colunas}
                    data={dados.GROUPED?.map((d,i) =>({
                        ...d,
                        CD_STS: d.CD_STS == 1 ? 'AGUARDANDO NA FILA'
                            : d.CD_STS   == 2   ? 'LIBERADO OPERAÇÃO'
                            : d.CD_STS   == 3   ? 'LIBERADO OPERAÇÃO'
                            : d.CD_STS   == 6   ? 'LIBERADO OPERAÇÃO'
                            : d.CD_STS   == 20  ? 'LIBERADO OPERAÇÃO'
                            : d.CD_STS   == -1  ? 'ENCERRADO'
                            : d.CD_STS   == -3  ? 'CANCELADO'
                            : d.CD_STS   == -9  ? 'NO SHOW'
                            : d.CD_STS   == 5  ? 'EM TRÂNSITO'
                            : ''
                    }))}
                />
            </div>
        </c.Frame>
    );
}

