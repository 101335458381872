import moment from "moment"
import { formatCpfCnpj, formatNumber } from "../../../f"

const valueWithDefault = (value) => value ?? 'N/A'

const cpfCnpj = (value) => {
  if((value ?? null) === null) return valueWithDefault(value)

  return formatCpfCnpj(value)
}

const dateTo = (date) => {
  if((date ?? null) === null) return valueWithDefault(date)

  return  moment(date).format('DD/MM/YYYY')
}

const kilogramTo = (value) => {
  if((value ?? null) === null) return valueWithDefault(value)

  return `${formatNumber(value, 1)} Kg`
}

export class TravelRecordPresenter  {
  constructor(record) {
    this.record = record
  }

  valueOf (field) {
    if(this[field]) return this[field]()

    return valueWithDefault(this.record[field])
  }

  taker () {
    return [
      valueWithDefault(this.record?.takerId),
      cpfCnpj(this.record.takerCnpj),
      valueWithDefault(this.record?.taker),
    ].join(' - ');
  }

  commercialPartner () {
    return [
      valueWithDefault(this.record?.commercialPartnerId),
      cpfCnpj(this.record.commercialPartnerCnpj),
      valueWithDefault(this.record?.commercialPartner),
    ].join(' - ');
  }

  shippingCompany () {
    return [
      valueWithDefault(this.record?.shippingCompanyId),
      cpfCnpj(this.record.shippingCompanyCnpj),
      valueWithDefault(this.record?.shippingCompany),
    ].join(' - ');
  }

  product () {
    return [
      this.record?.productGroupId,
      this.record?.productGroup
    ].join(' - ')
  }

  deadline () {
    return dateTo(this.record?.deadline)
  }

  nfeWeight () {
    return kilogramTo(this.record?.nfeWeight)
  }

  ticketWeight () {
    return kilogramTo(this.record?.ticketWeight)
  }

  breakageWeight () {
    return kilogramTo(this.record?.breakageWeight)
  }

}
