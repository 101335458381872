//VD-10500 Por Hugo em 23/04/24 - Incluido nfp
import React, { useState, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function NfpModal({portaria, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.dfe},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [data, setData] = useState({})

  async function submit(){
    let doc = "";

    if (data?.pdf) {
      doc = data.pdf.split(',')[1]??data.pdf;
    } else {
      doc = await f.fileToBase64( data.images[0].file )
    }

    setLoading(true);

    return App.api('portarias::gravaDocumento', {
      id_uocc: portaria.ID_UOCC ?? portaria.ticketId,
      tp_dfe: 1, //NF Avulsa
      dt_dco: data.dt_nf,
      nr_dco: data.nr_nf,
      nr_serie: data.nr_serie,
      qt_nf: data.qt_nf,
      vl_nf: data.vl_nf,
      nr_cnpj: data.nr_cpf,
      nm_emit: data.nm_pes,
      in_validar: false,
      nm_imagem: data.images[0].name,
      imagem: doc,
      ds_tag: 'nfp',
    }).then(r => {
      onFinish()
      return r.status
    }).finally(() => setLoading(false))
  }

  return(
    <c.Modal title={'Anexar Nota Produtor'} onClose={onClose} onFinish={submit} loading={loading} largeWidth
      validate={!!data.nr_cpf && !!data.nm_pes && !!data.dt_nf && !!data.nr_nf && !!data.nr_serie && !!data.qt_nf && !!data.vl_nf && !!data.images?.length}
    >
      <div className='f g1'>
        <c.Span label={lang.veiculo} value={portaria.ID_VCL??''} />
        <c.Span label={lang.produto} value={portaria.NM_PRO??''} />
        <c.Span label={lang.destino} value={portaria.NM_DST??''} />
      </div>

      <div className='f g1'>
        <div className='w1-3 f g1 f-column'>
          <div className='f g1 f-column'>
            <div className='f g1'>
              <c.Input label={"CPF produtor"} error={!f.validateCPF(data.nr_cpf)} required
                value={f.formatCpf(data.nr_cpf)}
                onChange={e => setData({...data, nr_cpf: f.strNums(e.target.value)})} className="w1-3"
                info={!f.validateCPF(data.nr_cpf) ? 'Documento inválido' : ''}
              />
              <c.Input label={"Nome produtor"} error={!data.nm_pes} required value={data.nm_pes} onChange={e => setData({...data, nm_pes: e.target.value})} className="w2-3" />
            </div>
            <div className='f g1'>
              <c.Input label={"Data emissão"} error={!data.dt_nf} required type="date" value={data.dt_nf} onChange={e => setData({...data, dt_nf: e.target.value})} className="w1-3" />
              <c.Input label={"Número"} error={!data.nr_nf} required type="number" value={data.nr_nf} onChange={e => setData({...data, nr_nf: e.target.value})} className="w1-3" />
              <c.Input label={"Série"} error={!data.nr_serie} required value={data.nr_serie} onChange={e => setData({...data, nr_serie: e.target.value})} className="w1-3" />
            </div>
            <div className='f g1'>
              <c.Input label={lang.quantidade} error={!data.qt_nf} required value={f.formatNumber(data.qt_nf, 3)} onChange={e => setData({...data, qt_nf: f.toFloat(e.target.value, 3)})} className="f1ticket" />
              <c.Input label={lang.valor} error={!data.vl_nf} required value={f.formatNumber(data.vl_nf, 2)} onChange={e => setData({...data, vl_nf: f.toFloat(e.target.value, 2)})} className="f1ticket" />
            </div>
          </div>

          {/**"application/pdf": ['.pdf'],  */}
          <c.Upload accept={{"application/pdf": ['.pdf'], "image/png": ['.png'], "image/jpeg": ['.jpg'],}}
            label="Arquivo aceito: PDF, JPG, e PNG. Máximo de 1 arquivo." maxFiles={1} files={data.images??[]}
            onChange={async e => {
              let pdf = null;

              if (e?.[0]?.file?.type === "application/pdf") {
                try {
                  const fileContent = await f.readFile(e?.[0]?.file);
                  pdf = fileContent;
                } catch (error) {
                  console.error('Erro ao ler arquivo PDF:', error);
                }
              }

              setData({
                ...data,
                images: e,
                pdf: pdf
              });

            }}
            className='w100' />
        </div>

        <div className='w2-3'>
          <c.Span label={"Pré-visualização"} className='center-v'
            value={!!data?.images?.[0]
                    ? (data?.images?.[0].file?.type === "application/pdf" ?
                      <c.Frame alt="Preview" className="w100" ><c.PreviewPDF file={data.pdf} /></c.Frame>
                      : <img src={data?.images?.[0]?.preview} alt="Preview" className='w100' style={{maxHeight: 400}} />
                    )
                    : <div className='p10'>Selecione um arquivo para visualizar</div>
                  }
          />
        </div>
      </div>
    </c.Modal>
  )
}
