import { useCallback, useContext, useMemo, useState } from "react"
import Papa from 'papaparse';

import { AppContext } from "../../../App";
import { Frame, Table } from "../../../c"
import { CardFilters } from "../../../c/CardFilters"
import { getCardFilters } from "../../../useCases/getCardFilters";

import cardFiltersConfig from './contents/card_filters_config.json'

import { SelectedControl } from "./controls/SelectedControl"
import { InvoicingUploadControl } from "./controls/InvoicingUploadControl"
import { InvoicingModal } from "./modals/InvoicingModal";
import { filterRecords } from "./useCases/filterRecords"
import { getFrameActions } from "./useCases/getFrameActions"
import { processInvoicesInBatch } from "./useCases/processInvoicesInBatch";
import { selectItemInList } from "./useCases/selectItemInList"
import { transformToColumn } from "./useCases/transformToColumn"
import { transformToRow } from "./useCases/transformToRow"

const invoicingUploadId ='invoicing-upload'

export const TravelRecords = ({
  filters,
  lang,
  loading,
  onChangeRecords,
  onFetchRecords,
  records = [],
}) => {
  const App = useContext(AppContext)
  const { api, confirm, toast, user } = App
  const {in_uorgadm, in_trp } = user

  const [selectedCardFilter, setCardFilter] = useState({})
  const [openInvoicingModal, setOpenInvoicingModal] = useState(false)

  const handleFilterCard = useCallback((newFilter) => {
    setCardFilter(newFilter)
  }, [])

  const cardFilters = useMemo(() => {
    return getCardFilters(cardFiltersConfig, {
      data: records,
      lang: lang.card_filters,
      selectedFilter: selectedCardFilter,
      onFilter: handleFilterCard
    })
  }, [handleFilterCard, lang.card_filters, records, selectedCardFilter])

  const handleSelectAllRows = useCallback((selected) => {
    const newRecords = records.map((record) => ({ ...record, selected }))

    onChangeRecords(newRecords)
  }, [onChangeRecords, records])

  const handleSelectRow = useCallback((record) => {
    return (selected) => {
      const newList = selectItemInList({ item: record, selected, list: records })

      onChangeRecords(newList)
    }
  }, [onChangeRecords, records])

  const handleClickInvoiceButton = useCallback((opened) => {
    return () => {
      setOpenInvoicingModal(opened)
    }
  }, [])

  const handleLoadSubData = useCallback(async (rowId, { record }) => {
    //await api dos dados do detalhe - Chamada GetExtras rowData.ID_UOCC
    const { results: extras } = await App.api(
      'controllership::detail',
      { ID_UOCC: record.id.idUocc }
    )

    Object.entries(extras).forEach(([key, value]) => {
      record[key] = value
    })
  }, [App])

  const [columns, rows, selectedRows] = useMemo(() => {
    const { filtered: filteredRecords, selected: selectedNewRecords } = filterRecords({
      records,
      filter: selectedCardFilter
    })
    const { api, confirm, user } = App

    const newColumns = transformToColumn({
      filteredRecords,
      lang: lang.columns,
      onToggle: handleSelectAllRows,
      selectedRecords: selectedNewRecords,
      user,
    })

    const rowParams = {
      api,
      columns: newColumns,
      confirm,
      lang,
      onFetchRecords,
      user,
    }

    return [
      newColumns,
      filteredRecords.map((record) => transformToRow({ // rows
        ...rowParams,
        onToggle: handleSelectRow(record),
        record,
      })),
      selectedNewRecords.map((record) => transformToRow({ //selectedRows
        ...rowParams,
        onToggle: handleSelectRow(record),
        record,
      }))
    ]
  }, [App, handleSelectAllRows, handleSelectRow, lang, onFetchRecords, records, selectedCardFilter])

  const frameActions = useMemo(() => {
    const records = rows.map(({ record }) => record)
    const selectedRecords = selectedRows.map(({ record }) => record)
    const approvable = in_uorgadm === 'T'
    const disapprovable = in_uorgadm === 'T'
    const invoicingProcessable = in_uorgadm === 'T'
    const billable = in_trp === 'T'

    return getFrameActions({
      api,
      approvable,
      billable,
      confirm,
      disapprovable,
      invoicingProcessable,
      invoicingUploadId,
      lang,
      onClickInvoincing: handleClickInvoiceButton(true),
      onReload: onFetchRecords,
      records,
      selectedRecords,
    })
  }, [api, confirm, handleClickInvoiceButton, in_trp, in_uorgadm, lang, onFetchRecords, rows, selectedRows])


  const handleSelectFile = useCallback(async (event) =>  {
    const file = event.target.files[0]

    if (!file) return;

    Papa.parse(file, {
      complete: (result) => {
        processInvoicesInBatch(result, {
          api,
          lang: lang.invoicing_in_batch,
          onFetchRecords,
          toast
        })
      },
      header: false, // o header está em um formato despadronizado
    })

    event.target.value = '';
  }, [api, lang.invoicing_in_batch, onFetchRecords, toast])

  return (
    <>
      <Frame>
        <CardFilters visible filters={cardFilters} />
      </Frame>

      <Frame
        actions={frameActions}
        controls={[
          ['selected', <SelectedControl count={selectedRows.length} lang={lang} />],
          ['upload', <InvoicingUploadControl id={invoicingUploadId} acceptExtension=".csv" onChange={handleSelectFile} />],
        ]}
        title={lang.title}
      >
        <Table
          loading={loading}
          hasSub={'details'}
          columns={columns}
          data={rows}
          onLoadSubData={handleLoadSubData}
        />
      </Frame>

      {openInvoicingModal && (
        <InvoicingModal
          visible={openInvoicingModal}
          columns={columns}
          rows={selectedRows}
          onClose={handleClickInvoiceButton(false)}
          onFinish={onFetchRecords}
        />
      )}
    </>
  )
}
