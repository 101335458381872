import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

const TbBtn = ({ status, title, tipo, portaria, color='primary', onClick }) => {
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.transportador}

  const handleClick = useCallback(() => {
      if(!status) return

      const idUocc = portaria.ID_UOCC ?? portaria.idUocc

      if( tipo === 'nota_de_venda' || tipo === 'mdfe' ) {
        const fileId = btoa(`${tipo}|${idUocc}`)

        window.open( `${App.baseURL}download/?k=${fileId}`, '_blank')

        return
      }

      if(onClick) onClick({ tipo, idPrt: idUocc })
  }, [App.baseURL, onClick, portaria.ID_UOCC, portaria.idUocc, status, tipo])

  return (
    <button
      className={[color,'nowrap'].join(' ')}
      disabled={!status}
      title={title}
      onClick={handleClick}
    >
      {lang[tipo]}
    </button>
  )
}

export function PortariaDownloadButtons({ portaria, auditoria=false }){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  const [previewDocumentModal, setPreviewDocumentModal] = useState({})

  const handleButtonClick = useCallback(({ tipo, idPrt }) => {
    console.log(tipo, ' - ', idPrt);
    setPreviewDocumentModal({ tipo, idPrt })
  }, [])

  return(
    <div className='f f-wrap g1 downloads'>
      <TbBtn
        status={!!portaria.IN_NFE_XML || (!!portaria.nfe_status||!!portaria.nfeStatus||!!portaria.LST_NFE)}
        tipo='nfe'
        title={lang.nota_fiscal_eletronica}
        color={['success','success','warning','cancel'][portaria.nfe_status ?? portaria.nfeStatus]}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.cte_status || !!portaria.cteStatus||!!portaria.LST_CTE}
        tipo='cte'
        title={lang.conhecimento_transporte_eletronico}
        color={['primary','success','warning','cancel'][portaria.cte_status ?? portaria.cteStatus]}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.mdfe_status || !!portaria.mdfeStatus}
        tipo='mdfe'
        title={lang.manifesto_fiscal_eletronico}
        color={['primary','success','warning','cancel'][portaria.mdfe_status ?? portaria.mdfeStatus]}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.nfp_status || !!portaria.nfpStatus || !!portaria.LST_NFP}
        tipo='nfp'
        title={lang.nota_fiscal_produtor}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.nota_venda_status || !!portaria.nota_vendaStatus || !!portaria.LST_NOTA_DE_VENDA}
        tipo='nfv'
        title={lang.nota_fiscal_venda}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.nota_devolvida_status || !!portaria.nota_devolvidaStatus || !!portaria.LST_NFE_DEVOLVIDA}
        tipo='nfd'
        title={lang.nota_fiscal_devolvida}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.ordem_status || !!portaria.ordemStatus || !!portaria.LST_ORDEM}
        tipo='oc'
        color={'success'}
        title={lang.ordem_carregamento}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.ticket_pesagem_status || !!portaria.ticketPesagemStatus || !!portaria.LST_TICKET_PESAGEM}
        tipo={auditoria ? 'td' : 'ticket_pesagem'}
        title={lang.ticket_descarga}
        color={['success','success','warning','cancel'][portaria.ticket_pesagem_status ?? portaria.ticketPesagemStatus]}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.eat_status || !!portaria.eatStatus || !!portaria.LST_AET}
        tipo='aet'
        title={lang.autorizacao_transporte}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.cnh_status || !!portaria.cnhStatus || !!portaria.CNH_DOC_ID}
        tipo='cnh'
        title={lang.carteira_habilitacao}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.crlv_status || !!portaria.crlvStatus || !!portaria.CRLV_DOC_ID}
        tipo='crlv'
        title={lang.certificado_licenciamento}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.rf_status || !!portaria.rfStatus || !!portaria.LST_RF}
        tipo='rf'
        title={lang.resumo_frete}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      <TbBtn
        status={!!portaria.fatura_status || !!portaria.faturaStatus}
        tipo='fatura'
        title={lang.fatura}
        color={'success'}
        portaria={portaria}
        onClick={handleButtonClick}
      />
      {!!previewDocumentModal.tipo && (
        <PreviewDocumentModal
          previewDocumentModal={previewDocumentModal}
          onClose={()=>setPreviewDocumentModal({})}
        />
      )}
    </div>
  )
}

// VD-9853 - Thales - 12/04/2024
export function PedidoDownloadButtons({pedido}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  const [previewDocumentModal, setPreviewDocumentModal] = useState({})

  function TbBtn({status, title, tipo, portaria, color='primary'}){
    return (
      <button className={[color,'nowrap'].join(' ')}
        style={{fontSize: 12, padding: '2px 5px', maxWidth: 'initial', flex: 1}}
        disabled={!status}
        title={title}
        onClick={() => {
          if( !!status ) {
            if( tipo === 'nota_de_venda' || tipo === 'mdfe' ){
              window.open(App.baseURL+'download/?k=' + btoa(tipo + '|' + portaria.ID_UOCC), '_blank')
            }else{
              setPreviewDocumentModal({
                tipo,
                idPrt: portaria.ID_UOCC_APR
              })
            }
          }
        }}
      >
        {lang[tipo]}
      </button>
    )
  }

  return(<>
    <div className='f f-wrap g1' style={{width: 50}}>
      <TbBtn
        status={!!pedido.IN_NFE_XML}
        tipo='nfe'
        color={['primary','success','warning','cancel'][pedido.nfe_status ?? pedido.nfeStatus]}
        portaria={pedido}
      />
    </div>

    {!!previewDocumentModal.tipo && (
      <PreviewDocumentModal
        previewDocumentModal={previewDocumentModal}
        onClose={()=>setPreviewDocumentModal({})}
      />
    )}
  </>)
}

function PreviewDocumentModal({previewDocumentModal, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const {tipo, idPrt} = previewDocumentModal

  //VD-10500 Por Hugo em 23/04/24 - Incluido nfp
  const [data, setData] = useState({}),
        [nfes, setNfes] = useState([]),
        [nfps, setNfps] = useState([]),
        [originalLink, setOriginalLink] = useState(''),
        [preview, setPreview] = useState(''),
        [nfe_selected, setNfe_selected] = useState(""),
        [ticketPesagem, setTicketPesagem] = useState([]);

  const ticketLabel = {
    TICKET: "Número do Ticket",
    PRODUTO: "Produto",
    PLACA: "Placa",
    PESO_TARA: "Peso Tara",
    PESO_LIQUIDO: "Peso Líquido",
    PESO_BRUTO: "Peso Bruto",
    DT_SAIDA: "Data Saída",
    DT_ENTRADA: "Data Entrada",
  };

  const mapearValorTicket = (data) => {
    return data.map((item) => ({
      label: ticketLabel[item.ID_FLD.trim()] || item.ID_FLD.trim(),
      value: item.VL_FLDO,
    }));
  }

  const buscarValorTicket = (label) => {
    const item = ticketPesagem.find((item) => item.label === label);
    return item ? item.value : "";
  }

  function set_dco(){
    //VD-12493 por Hugo em 07/05/2024
    //let _originalLink = App.baseURL+'download/?k=' + btoa(tipo + '|' + idPrt + '|' + nfe_selected)
    const randomNumber = Math.floor(Math.random() * 1000);
    let _originalLink = App.baseURL+'download/?k=' + btoa(tipo + '|' + idPrt + '|' + nfe_selected) + '&cache=' + randomNumber
    //FIM VD-12493

    setOriginalLink( _originalLink )
    setPreview( tipo === 'nfe' || tipo === 'cte' || tipo === 'cte-mm'
                    ? App.baseURL + 'download/?k=' + btoa('da'+tipo + '|' + idPrt + '|' + nfe_selected)
                    : _originalLink )
  }

  useEffect(() => {
    App.api('portarias::getExtras', {
      ID_UOCCs: [idPrt]
    }).then(r => setData(r.results))
  }, [])

  useEffect(() => {
    log('teste');
    if( ['nfe','cte'].includes(tipo) && !!data.dcos ){
      let _nfes = data.dcos[idPrt].filter(dco => parseInt(dco.TP_DF) === (tipo==='nfe'?55:tipo==='cte'?57:55))
      setNfes( _nfes )
      setNfe_selected( _nfes[0]?.ID_UOCC )
    //VD-10500 Por Hugo em 23/04/24 - Incluido nfp
    }else if ( ['nfp'].includes(tipo) && !!data.nfps ) {
      let _nfps = data.nfps[idPrt][0]
      setNfps( _nfps )
    }else if( ['crlv'].includes(tipo) && !!data.crlvs ) {
      let _crlvs = data.crlvs[idPrt]
      setNfes( _crlvs )
      log(_crlvs)
      setNfe_selected( _crlvs[0]?.ID_UOCC )
    } else if (["ticket_pesagem"].includes(tipo) && !!data.ticket_pesagem) {
      const _ticket_pesagem = data.ticket_pesagem[idPrt] || [];
      setTicketPesagem(mapearValorTicket(_ticket_pesagem));
    } else {
      set_dco();
    }
  }, [data])

  useEffect(() => set_dco(), [nfe_selected])

  return(
    <c.Modal largeWidth
      extraControls={<>
        <a href={preview} download className='button'><icons.MdCloudDownload/> Download</a>
        {(['nfe','cte','cte-mm'].includes(tipo)) &&
          <a href={originalLink} download className='button'><icons.MdCloudDownload/> XML</a>
        }
      </>}
      title={'Document Preview - ' + tipo.toUpperCase()} onClose={onClose}>

      {['nfe','cte','cte-mm'].includes(tipo) && nfes.length > 1 &&
        <div className='f g1 w100'>
          {nfes.map((nfe, nfe_id) =>
            <button key={nfe_id} className={nfe_selected === nfe.ID_UOCC ? 'info' : ''} onClick={() => setNfe_selected(nfe.ID_UOCC)}>{nfe.NR_DCO}</button>)
          }
        </div>
      }
      {tipo==='nfp' &&
        <div className='f g1 w100'>
          <div className='f g1'>
            <c.Span label={"CPF produtor"} value={f.formatCpf(nfps.NR_CPFCNPJ??'')} />
            <c.Span label={"Nome produtor"} value={nfps.NM_PES??''} />
          </div>
          <div className='f g1'>
            <c.Span label={"Data emissão"} value={nfps.DT_DCO??''} />
            <c.Span label={"Número"} value={nfps.NR_DCO??''} />
            <c.Span label={"Série"} value={nfps.CD_DCO??''} />
          </div>
          <div className='f g1'>
            <c.Span label={lang.quantidade} value={f.formatNumber(nfps.QT_ORG??0, 3)} />
            <c.Span label={lang.valor} value={f.formatNumber(nfps.VL_ORG??0, 2)} />
          </div>
        </div>
      }
      {['crlv'].includes(tipo) && nfes.length > 1 &&
        <div className='f g1 w100'>
          {nfes.map((crlv, crlv_id) =>
            <button key={crlv_id} className={nfe_selected === crlv.ID_UOCC ? 'info' : ''} onClick={() => setNfe_selected(crlv.ID_UOCC)}>{crlv.ID_VCL}</button>)
          }
        </div>
      }
      {tipo === "ticket_pesagem" && ticketPesagem.length > 0 && (
        <>
          <div className="f g1 w100">
            <div className="f g1">
              <c.Span
                label={ticketLabel["TICKET"]}
                value={buscarValorTicket("Número do Ticket")}
              />
              <c.Span
                label={ticketLabel["PRODUTO"]}
                value={buscarValorTicket("Produto")}
              />
              <c.Span
                label={ticketLabel["DT_ENTRADA"]}
                value={buscarValorTicket("Data Entrada")}
              />
              <c.Span
                label={ticketLabel["DT_SAIDA"]}
                value={buscarValorTicket("Data Saída")}
              />
              <c.Span
                label={ticketLabel["PLACA"]}
                value={buscarValorTicket("Placa")}
              />
            </div>
          </div>
          <div className="f g1 w100">
            <div className="f g1">
              <c.Span
                label={ticketLabel["PESO_BRUTO"]}
                value={buscarValorTicket("Peso Bruto")}
              />
              <c.Span
                label={ticketLabel["PESO_LIQUIDO"]}
                value={buscarValorTicket("Peso Líquido")}
              />
              <c.Span
                label={ticketLabel["PESO_TARA"]}
                value={buscarValorTicket("Peso Tara")}
              />
            </div>
          </div>
        </>
      )}
      <object data={preview + '&inline=true'} type="application/pdf" width="100%" height={600}>
        <p>Não foi possível exibir o arquivo. Faça o <a href={preview}>Download</a>.</p>
      </object>

    </c.Modal>
  )
}
