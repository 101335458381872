import { useState, useEffect, useContext } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as p from '..'
import * as f from '../../f'

export default function GerencialOperacao(){
  const App = useContext(AppContext)
  let lang = {...App.lang.global, ...App.lang.dashCargaDescarga, ...App.lang.GerencialOperacao}
  let icons = App.icons

  const [dtIni, setDtIni] = useState( App.moment().toDate() )
  const [dtFim, setDtFim] = useState( App.moment().toDate() )
  const [data, setData] = useState({})
  const [listas, setListas] = useState(null)
  const [filtro, setFiltro] = useState(null)
  const [cancelAgendado, setCancelAgendado] = useState({})
  const [loading, setLoading] = useState(null)
  const [trpGraphData, setTrpGraphData] = useState([])
  const [trpGraphActiveIndex, setTrpGraphActiveIndex] = useState(0)
  const [busca, setBusca] = useState('')

  const sourceTypeOptions = [
    { value: '', label: 'Todos' },
    { value: 'Proprio', label: 'Próprios' },
    { value: 'Terceiro', label: 'Terceiros' }
  ];

  const graphPieColors = ['brown','green','blue','orange','darkkhaki','cornflowerblue','red','blueviolet','burlywood','gray']

  function getListas(){ dtIni&&dtFim&&App.api('gerencial::getListas', {
    ini: App.moment(dtIni).format('YYYY-MM-DD'), fim: App.moment(dtFim).format('YYYY-MM-DD')
  }).then(r=>setListas( r.results )) }

  useEffect(()=>getListas(), [dtIni, dtFim])

  function processPortaria(data){
    let QT_VCL = data.lst_portaria[7].QT_VCL
    data.lst_portaria[7] = (f.groupContrato(data.lst_portaria[7].LST_VCL,['ID_CN','NM_TRP'],'QT_ORG','Encerrado'))
    data.lst_portaria[7].QT_VCL = QT_VCL

    return data
  }

  function get(){
    setLoading(true)
    setData({})
    App.api('gerencial::consulta', {
      ini: App.moment(dtIni).format('YYYY-MM-DD'),
      fim: App.moment(dtFim).format('YYYY-MM-DD'),
      und: filtro?.unidades,
      prc: filtro?.clientes,
      ori: filtro?.origens,
      dst: filtro?.destinos,
      id_pro: filtro?.produtos,
      tp_op: filtro?.operacoes,
      tipos_origem: filtro?.tipos_origem,
    }).then(r=>{
      const processedData = processPortaria(r.results)
      console.log('Resposta da API:', r.results); // Verifique o conteúdo da resposta
      setData( processedData )
      setTrpGraphData(
        (() => {
          const total = processedData.lst_portaria[1].LST_VCL.reduce((acc,cur)=>acc+parseFloat(cur.QT_ORG), 0)
          const trp_sorted = processedData.lst_portaria[1].LST_VCL.sort((a,b) => b.QT_ORG - a.QT_ORG).map(trp=>({
            name: trp.NM_TRP,
            qnt: parseFloat(trp.QT_ORG),
            percent: parseInt(parseFloat(trp.QT_ORG) / total * 10000) / 10000
          }))

          return [...trp_sorted.slice(0, 9), trp_sorted.slice(9).reduce((acc,cur)=>({
            name: 'Outros',
            qnt: parseInt( ((acc.qnt??0)+cur.qnt) * 100) / 100,
            percent: parseInt( ((acc.qnt??0)+cur.qnt) / total * 10000) / 10000
          }),{})]
        })()??[]
      )
    }).finally(()=>setLoading(null))
  }

  const colunasTable = [
    [ // Cliente 0
      [lang.cliente, 'NM_PRC', {}, 'center-h'],
      [lang.ori_des, 'ori_des',{}, 'center-h'],
      [lang.cota_cif_fob, 'cota_cif_fob',{}, 'center-h'],
      [lang.redistribuida, 'QT_DISTRIBUIDA',{}, 'center-h'],
      [lang.consumida_disponivel, 'consumida_disponivel',{}, 'center-h'],
      [lang.agendado, 'QT_AGENDADO',{}, 'center-h'],
      [lang.transito, 'QT_TRANSITO',{}, 'center-h'],
      [lang.em_fila, 'QT_RECEPCAO',{}, 'center-h'],
      [lang.em_operacao, 'QT_OPERACAO',{}, 'center-h'],
      [lang.encerrado, 'QT_ENCERRADO',{}, 'center-h'],
      [lang.no_show, 'QT_NOSHOW',{}, 'center-h'],
      [lang.cancelado, 'QT_CANCELADO',{}, 'center-h'],
      [lang.volume_ton, 'QT_ORG',{}, 'center-h'],
    ],
    [ // Transportadora 1
      [lang.transportadora, 'NM_TRP', {}, 'center-h'],
      // [lang.cliente, 'NM_PRC'],
      [lang.cota, 'QT_COTA', {}, 'center-h'],
      [lang.disponivel, 'QT_DISPONIVEL', {}, 'center-h'],
      [lang.agendado, 'QT_AGENDADO', {}, 'center-h'],
      [lang.transito, 'QT_TRANSITO', {}, 'center-h'],
      [lang.em_fila, 'QT_RECEPCAO', {}, 'center-h'],
      [lang.em_operacao, 'QT_OPERACAO', {}, 'center-h'],
      [lang.encerrado, 'QT_ENCERRADO', {}, 'center-h'],
      [lang.no_show, 'QT_NOSHOW', {}, 'center-h'],
      [lang.cancelado, 'QT_CANCELADO', {}, 'center-h'],
      [lang.volume_ton, 'QT_ORG', {}, 'center-h'],
    ],
    [ // Contrato 2
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.cota, 'QT_COTA', {}, 'center-h'],
      [lang.disponivel, 'QT_DISPONIVEL', {}, 'center-h'],
      [lang.agendado, 'QT_AGENDADO', {}, 'center-h'],
      [lang.transito, 'QT_TRANSITO', {}, 'center-h'],
      [lang.em_fila, 'QT_RECEPCAO', {}, 'center-h'],
      [lang.em_operacao, 'QT_OPERACAO', {}, 'center-h'],
      [lang.encerrado, 'QT_ENCERRADO', {}, 'center-h'],
      [lang.no_show, 'QT_NOSHOW', {}, 'center-h'],
      [lang.cancelado, 'QT_CANCELADO', {}, 'center-h'],
      [lang.volume_ton, 'DT_VLD', {}, 'center-h'],
      [lang.cadencia_dia_ton, 'QT_CAD', {}, 'center-h'],
      [lang.termino_previsto, 'DT_DIAFIM', {}, 'center-h'],
      [lang.primeiro_exp, 'DT_INICIO', {}, 'center-h'],
      [lang.ultimo_exp, 'DT_ULTIMO', {}, 'center-h'],
      [lang.volume_ton, 'QT_SLC', {}, 'center-h'],
      [lang.volume_exp, 'QT_EXP', {}, 'center-h'],
      [lang.saldo_ton, 'QT_SALDO', {}, 'center-h'],
      [lang.percentual_exp, 'PR_EXP', {}, 'center-h'],
    ],
    [ // Agendado 3
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.turno, 'DS_TRN', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.cliente, 'NM_CLT', {}, 'center-h'],
      [lang.ori_des, 'DS_ORGDST', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.transportadora, 'NM_TRP', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.acao, 'action', {}, 'f center-h']
    ],
    [ // Transito 4
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.turno, 'DS_TRN', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.cliente, 'NM_CLT', {}, 'center-h'],
      [lang.ori_des, 'DS_ORGDST', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.volume_ton, 'QT_ORG', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.transportadora, 'NM_TRP', {}, 'center-h'],
      [lang.transito, 'DT_TRS', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.motorista, 'NM_MTR', {}, 'center-h'],
      [lang.valor, 'VL_ORG', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRVEST', {}, 'center-h'],
      [lang.status_viagem, 'CD_STSPRV', {}, 'center-h'],
      [lang.progresso, 'PR_VGM', {}, 'center-h'],
      [lang.tempo_percorrido, 'HR_PERCORRIDO', {}, 'center-h'],
      [lang.tempo_rota, 'HR_TRANSITO', {}, 'center-h'],
      [lang.tempo_destino, 'HR_DESTINO', {}, 'center-h'],
      [lang.km_rota, 'QT_KMTOTAL', {}, 'center-h'],
      [lang.km_percorrido, 'QT_KM', {}, 'center-h'],
      [lang.km_restante, 'QT_KMFALTA', {}, 'center-h'],
      ['Ação', 'action', {}, 'f center-h']
    ],
    [ // Em fila 5
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.volume_ton, 'QT_ORG', {}, 'center-h'],
      [lang.local, 'ID_LFIS', {}, 'center-h'],
      [lang.data_entrada, 'DT_EFILA', {}, 'center-h'],
      [lang.tempo, 'QT_TEMPO', {}, 'center-h'],
    ],
    [ // Operação 6
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.volume_ton, 'QT_ORG', {}, 'center-h'],
      [lang.local, 'ID_LFIS', {}, 'center-h'],
      [lang.entrada, 'DT_OPR1', {}, 'center-h'],
      [lang.tempo, 'QT_TEMPO', {}, 'center-h'],
    ],
    // [ // Encerrado 7
    //   [lang.unidade, 'ID_UORG'],
    //   [lang.veiculo, 'ID_VCL'],
    //   [lang.contrato, 'ID_CN'],
    //   [lang.ticket, 'ID_UOCC'],
    //   [lang.data_prevista, 'DT_PRV'],
    //   [lang.operacao, 'DS_TPOPRPRT'],
    //   [lang.produto, 'DS_PRD'],
    //   [lang.volume_ton, 'QT_ORG'],
    //   [lang.local, 'ID_LFIS'],
    //   [lang.km_percorrido, 'QT_KM'],
    //   [lang.km_rota, 'QT_KMTOTAL'],
    //   [lang.km_divergente, 'QT_KMDIV'],
    // ],
    [//Teste de encerrado 11
      [lang.transportadora, 'NM_TRP', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.volume_ton, 'QT_ORG', {}, 'center-h'],
    ],
    [ // Cancelado 8
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.local, 'ID_LFIS', {}, 'center-h'],
    ],
    [ // NoShow 9
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.veiculo, 'ID_VCL', {}, 'center-h'],
      [lang.contrato, 'ID_CN', {}, 'center-h'],
      [lang.ticket, 'ID_UOCC', {}, 'center-h'],
      [lang.data_prevista, 'DT_PRV', {}, 'center-h'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'center-h'],
      [lang.produto, 'DS_PRD', {}, 'center-h'],
      [lang.local, 'ID_LFIS', {}, 'center-h'],
    ],
    [ // Tipo Veículo 10
      [lang.tipo, 'NM_TVCL', {}, 'center-h'],
      [lang.quantidade, 'QT_VEICULO', {}, 'center-h'],
      [lang.unidade, 'ID_UORG', {}, 'center-h'],
      [lang.percentual, 'PR_VEICULO', {}, 'center-h'],
    ],

  ]

  useEffect(() =>{ // Melhor jeito de lidar com as atualizacoes de linguagem?
    if( data.lst_portaria ){
      var auxData = data
      // Cliente
      data.lst_portaria[0].LST_VCL = data.lst_portaria?.[0].LST_VCL.map(v=>({...v,
        ori_des: v.NM_ORI+' / '+v.NM_DST,
        cota_cif_fob: v.QT_COTA + ' (' + v.QT_CIF + ' / ' + v.QT_FOB + ')',
        consumida_disponivel: v.QT_COTA + ' / ' + v.QT_DISPONIVEL
      }))

      // Agendado
      data.lst_portaria[3].LST_VCL = data.lst_portaria?.[3].LST_VCL.map(v=>({...v,
        action:
          <button onClick={() => setCancelAgendado({open: true, id: v.ID_UOCC, vcl: v.ID_VCL, motivo: ''})} title={App.lang.global.cancel}>
            {icons.MdCancel()}
          </button>,
      }))

      // Transito
      data.lst_portaria[4].LST_VCL = data.lst_portaria?.[4].LST_VCL.map(v=>({...v,
        action:
          <button onClick={() => setCancelAgendado({open: true, id: v.ID_UOCC, vcl: v.ID_VCL, motivo: ''})} title={App.lang.global.cancel}>
            {icons.MdCancel()}
          </button>,
      }))
      setData(auxData)
    }
  },[data,App.lang])
  const style = {minWidth: 400}

  return(<>
    <c.Frame className="filter-frame" autoScroll={false} flex style={{zIndex: 1}}>
      <c.DaterangePicker label={lang.data} startDate={dtIni} endDate={dtFim} onChange={(ini, fim)=>{setDtIni(ini);setDtFim(fim)}} />
      {/* <c.Input type="date" label="Data inical" value={dtIni} onChange={e=>setDtIni(e.target.value)} className="f1" />
      <c.Input type="date" label="Data final"  value={dtFim} onChange={e=>setDtFim(e.target.value)} className="f1" /> */}

      <c.Select value={filtro?.unidades} onChange={v=>setFiltro({...filtro, unidades: v.map(v=>v.value)})} options={listas?.unidades} label={lang.unidades} multi style={style} searchable className='f3' />
      <c.Select value={filtro?.clientes} onChange={v=>setFiltro({...filtro, clientes: v.map(v=>v.value)})} options={listas?.clientes} label={lang.clientes} multi style={style} searchable className='f3' />
      {/* <c.Select value={filtro?.sourceTypeFilter} onChange={v=>setFiltro({...filtro, sourceTypeFilter: v.map(v=>v.value)})} options={sourceTypeOptions} label={"Tipo de Origem"} multi style={style} searchable className='f3' /> */}
      {/* <c.Select value={sourceTypeFilter} onChange={v => setSourceTypeFilter(v.value)} options={sourceTypeOptions} label={"Tipo de Origem"} searchable className='f1' /> */}
      <c.Select
        value={filtro?.tipos_origem}
        onChange={v => setFiltro((prevState) => ({ ...prevState, tipos_origem: v.value }))}
        options={sourceTypeOptions}
        label={"Tipo de Origem"}
        searchable
        className='f1'
      />
      {/* <c.Select value={filtro?.tipos_origem} onChange={v=>setFiltro({...filtro, sourceTypeFilter: v.map(v=>v.value)})} options={sourceTypeOptions} label={'Tipo Origem'} style={style} searchable className='f3' /> */}
      <c.Select value={filtro?.origens}  onChange={v=>setFiltro({...filtro, origens:  v.map(v=>v.value)})} options={listas?.origens}  label={lang.origens}  multi style={style} searchable className='f3' />
      <c.Select value={filtro?.destinos} onChange={v=>setFiltro({...filtro, destinos: v.map(v=>v.value)})} options={listas?.destinos} label={lang.destinos} multi style={style} searchable className='f3' />
      <c.Select value={filtro?.produtos} onChange={v=>setFiltro({...filtro, produtos: v.map(v=>v.value)})} options={listas?.produtos} label={lang.produtos} multi style={style} searchable className='f3' />
      <c.Select value={filtro?.operacoes} onChange={v=>setFiltro({...filtro, operacoes: v.map(v=>v.value)})} options={listas?.operacoes} label={lang.operacoes} multi style={style} searchable className='f3' />
      <button onClick={() => get()} disabled={!!App.loading} className='f1'>{icons.MdSearch({size: 24})} {App.lang.global.pesquisar}</button>
    </c.Frame>

    {!loading||<App.LoadingComponent/>}

    {!!data.lst_portaria && <c.Frame flex><p.GerencialOperacaoCards data={data} /></c.Frame>}

    {!!data.lst_portaria && <c.Frame control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable label={lang.busca}/>}>
      <c.Tabs>
        <c.TabList>
          {data.lst_portaria?.map((t, ti) =>
            <c.Tab key={ti}>{App.f.capitalize(t.DS_STS)}</c.Tab>
          )}
        </c.TabList>

        {data.lst_portaria?.map((t, i) => !!t && !!colunasTable[i] &&
          <c.TabPanel key={i}>
            <div className="f f-column g1 w100">
              {/* Gráfico em caso de Transportadoras */}
              {i===1 &&
                <div className="f g5 center-v center-h">
                  <c.chart.PieChart width={500} height={200}>
                    {/* <c.chart.Tooltip /> */}
                    <c.chart.Pie cx={'50%'} cy={'50%'} innerRadius={35} outerRadius={50}
                      data={trpGraphData} dataKey="qnt"
                      activeIndex={trpGraphActiveIndex}
                      onMouseEnter={(_, index) => setTrpGraphActiveIndex(index)}
                      activeShape={(props) => {
                        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
                        const sin = Math.sin(-(Math.PI / 180) * midAngle)
                        const cos = Math.cos(-(Math.PI / 180) * midAngle)
                        const sx = cx + (outerRadius + 10) * cos
                        const sy = cy + (outerRadius + 10) * sin
                        const mx = cx + (outerRadius + 30) * cos
                        const my = cy + (outerRadius + 30) * sin
                        const ex = mx + (cos >= 0 ? 1 : -1) * 22
                        const ey = my
                        const textAnchor = cos >= 0 ? 'start' : 'end'

                        return (
                          <g>
                            {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text> */}
                            <c.chart.Sector cx={cx} cy={cy} fill={fill}
                              startAngle={startAngle} endAngle={endAngle}
                              innerRadius={innerRadius} outerRadius={outerRadius}
                            />
                            <c.chart.Sector cx={cx} cy={cy} fill={fill}
                              startAngle={startAngle} endAngle={endAngle}
                              innerRadius={outerRadius + 4} outerRadius={outerRadius + 6}
                            />
                            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="var(--color)" fontSize={12}>
                              {payload.name}
                            </text>
                            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="var(--colorTextGray)" fontSize={12}>
                              {`${value} (${(percent * 100).toFixed(2)}%)`}
                            </text>
                          </g>
                        );
                      }}
                    >
                      {trpGraphData.map((dt, di) => <c.chart.Cell key={di} fill={graphPieColors[di]} />)}
                    </c.chart.Pie>
                  </c.chart.PieChart>

                  <div className="f g2 f-column">
                    {trpGraphData.slice(0,5).map((trpData, trpDataId) =>
                      <div className="f g1 center-v" onMouseEnter={()=>setTrpGraphActiveIndex(trpDataId)}>
                        <c.Dot color={graphPieColors[trpDataId]} />
                        <div className="f f-column left">
                          <span style={{
                            fontSize: 12,
                            fontWeight: trpGraphActiveIndex === trpDataId ? 'bold' : 'normal',
                          }}>
                            {trpData.name}
                          </span>
                          <span style={{fontSize: 12, color: 'var(--colorTextGray)'}}>
                            {trpData.qnt} - {parseInt(trpData.percent*10000)/100 + '%'}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="f g2 f-column">
                    {trpGraphData.slice(5).map((trpData, trpDataId) =>
                      <div className="f g1 center-v" onMouseEnter={()=>setTrpGraphActiveIndex(trpDataId+5)}>
                        <c.Dot color={graphPieColors[trpDataId+5]} />
                        <div className="f f-column left">
                          <span style={{
                            fontSize: 12,
                            fontWeight: trpGraphActiveIndex === trpDataId+5 ? 'bold' : 'normal',
                          }}>{trpData.name}</span>
                          <span style={{fontSize: 12, color: 'var(--colorTextGray)'}}>
                            {trpData.qnt} - {parseInt(trpData.percent*10000)/100 + '%'}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }

              <c.Table data={t.LST_VCL} search={busca}
                columns={colunasTable[i]}
              />
            </div>
          </c.TabPanel>
        )}
      </c.Tabs>
    </c.Frame>}

    {!!data.lst_aderencia?.length &&
      <c.Frame title={lang.aderencia} autoScroll={false}>
        <c.chart.ResponsiveContainer width="100%" minWidth={500} height={350}>
          <c.chart.ComposedChart data={data.lst_aderencia}>
            <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <c.chart.Tooltip /><c.chart.YAxis /><c.chart.XAxis dataKey="Data" />
            <c.chart.Area type="natural" dataKey="Cota" stroke="var(--info)" fill="var(--info)" strokeWidth={4} />
            <c.chart.Bar barSize={5} dataKey="Consumida" stackId="consumida" fill="var(--success)" />
          </c.chart.ComposedChart>
        </c.chart.ResponsiveContainer>
      </c.Frame>
    }

    {!!data.lst_aderenciaturno?.length &&
      <c.Frame title={lang.aderencia_performance_turno}>
        <c.chart.ResponsiveContainer width="100%" minWidth={500} height={350}>
          <c.chart.ComposedChart data={data.lst_aderenciaturno}>
            <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <c.chart.Tooltip /><c.chart.YAxis /><c.chart.XAxis dataKey="Turno" />
            <c.chart.Area type="natural" dataKey="Agendado" stroke="var(--info)" fill="var(--info)" strokeWidth={4} />
            <c.chart.Bar barSize={5} dataKey="Dentro" stackId="finalizada" fill="var(--success)" />
            <c.chart.Bar barSize={5} dataKey="Fora" stackId="finalizada" fill="var(--danger)" />
          </c.chart.ComposedChart>
        </c.chart.ResponsiveContainer>
      </c.Frame>
    }

    {/* Modais */}
    {cancelAgendado.open &&
      <c.Modal title={lang.cancelar_veiculo} onClose={()=>setCancelAgendado({})}
        onFinish={()=>
          App.api('portarias::alteraSituacao',{id_uocc: cancelAgendado.id, cd_sts: -3, ds_mtv: cancelAgendado.motivo })
            .then(r => {
              if( r.status ) {
                setCancelAgendado({})
                get()
                f.sendAppApiDeletePortaria({ App,
                  prtIdUocc: cancelAgendado.id,
                })
                return r.status
              }
            })
        }
        validate={cancelAgendado.motivo.length >= 10}
      >
        <c.Input value={cancelAgendado.motivo} minLength="10"
          label={lang.cancelar_veiculo_informe_motivo + cancelAgendado.vcl + lang.cancelar_veiculo_de_ticket + cancelAgendado.id}
          onChange={(e)=>setCancelAgendado({...cancelAgendado, motivo: e.target.value})} />
      </c.Modal>
    }
  </>)
}
