import { useState, useEffect, useContext, useCallback } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

/*
Restricao de 7 dias para o filtro
*/
export default function Filtros({ get, dtIni, dtFim, setDtIni, setDtFim, lstFiltro,setFiltro,filtro }) {
  const App = useContext(AppContext),
    // lang = { ...App.lang.gerencialQualidade, ...App.lang.global },
    icons = App.icons;

  const style = {};

  const onChangeIdRv = useCallback((e) =>{
      setFiltro({ ...filtro, id_rv: e})
  },[filtro,setFiltro])

  const onChangeContrato = useCallback((e) =>{
    setFiltro({ ...filtro, contrato: e})
},[filtro,setFiltro])

  return (
    <c.Frame className="filter-frame" autoScroll={false} flex style={{ zIndex: 1 }}>
      <c.DaterangePicker label='Data' startDate={dtIni} endDate={dtFim} onChange={(ini, fim) => {setDtIni(ini); setDtFim(fim); setFiltro({ ...filtro, ini: ini, fim: fim }) }} />
      <c.Select value={filtro?.unidades} onChange={v => setFiltro({ ...filtro, unidades: v.map(v => v.value) })} options={lstFiltro?.unidades} label={'Unidade de negócio'} multi style={style} searchable className="f4" />

      {App.user.in_uorgadm==='T' && <c.Select value={filtro?.parceiros} onChange={v => setFiltro({ ...filtro, parceiros: v.map(v => v.value) })} options={lstFiltro?.parceiros} label={'Parceiros'} multi style={style} searchable className="f4" />}

      <c.Select value={filtro?.produtos} onChange={v => setFiltro({ ...filtro, produtos: v.value })} options={lstFiltro?.produtos} label={'Produto'} style={style}  searchable className="f4" />

      <c.Select value={filtro?.operacoes} onChange={v => setFiltro({ ...filtro, operacoes: v.value })} options={lstFiltro?.operacoes} label={'Operação'} style={style} searchable className="f4" />
      {/* <c.Select value={filtro?.contratos} onChange={v => setFiltro({ ...filtro, contratos: v.map(v => v.value) })} options={lstFiltro?.contratos} label={'Contrato'} multi style={style} searchable className="f4" /> */}
      <InputFilter label={"Contrato"} onChange={onChangeContrato} value={filtro?.contrato} />
      <c.Select value={filtro?.modais} onChange={v => setFiltro({ ...filtro, modais: v.value })} options={lstFiltro?.modais} label={'Modal'} style={style} searchable className="f4" />
      {/* <c.Select value={filtro?.unidades} onChange={v => setFiltro({ ...filtro, unidades: v.map(v => v.value) })} options={lstFiltro?.unidades} label={'ID RV'} multi style={style} searchable className="f4" /> */}
      <InputFilter label={"ID RV"} onChange={onChangeIdRv} value={filtro?.id_rv} />
      <c.Select value={filtro?.aprovacoes} onChange={v => setFiltro({ ...filtro, aprovacoes: v.value})} options={lstFiltro?.aprovacoes} label={'Aprovação'} style={style} searchable className="f4" />
      <button onClick={() => get({
        periodos: [{
          ini: App.moment(dtIni).format('YYYY-MM-DD'),
          fim: App.moment(dtFim).format('YYYY-MM-DD')
        }]
      })} disabled={!!App.loading} >
        {icons.MdSearch({ size: 24 })} {App.lang.global.pesquisar}
      </button>
    </c.Frame>
  )

}

function InputFilter({ label, onChange, value }) {
  const handleChange = useCallback(({ target }) => {
    if (onChange) onChange(target.value)
  }, [onChange])

  return (
    <c.Input
      clearable
      className="f4"
      label={label}
      onChange={handleChange}
      value={value}
    />
  )
}
