import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '..'
import * as f from '../../f'

import TruckCharging from '../../a/truckCharging'

export default function OrdemCarregamneto({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.ordem_carregamento, ...App.lang.GerencialOperacao},
        icons = App.icons

  const [loading, setLoading] = useState(null),
        [data, setData] = useState([]),
        [incluir, setIncluir] = useState(null),
        [alterarDataTurno, setAlterarDataTurno] = useState(null),
        [viewDetails, setViewDetails] = useState(null),
        [search, setSearch] = useState(''),
        [showFilters, setShowFilters] = useState(null),
        [editing, setEditing] = useState(null),
        [filtroConcluidas, setFiltroConcluidas] = useState(false)

  function get(){
    setLoading(true)
    App.api('ordemCarregamento::getAll', {
      filtroConcluidas
    }).then(r => {
      setData( r.results.map(oc => ({...oc,
        TRANSPORTADORAS: oc.TRANSPORTADORAS.filter((f,y) => f.CD_STS !== -3),
        ITENS: oc.ITENS.filter((t,y) =>t.CD_STS != -3 ),
        PORTARIAS: oc.PORTARIAS.map(portaria => ({...portaria,
          DS_STS: portaria.CD_STS === '-1' ? lang.encerrado :
                  portaria.CD_STS === '1'  ? lang.em_fila :
                  portaria.CD_STS === '2'  ? lang.recepcionado :
                  portaria.CD_STS === '3'  ? lang.em_operacao :
                  portaria.CD_STS === '4'  ? lang.agendado :
                  portaria.CD_STS === '5'  ? lang.transito : ''
        })),
        TURNOS: oc.TURNOS.map(turno => ({...turno,
          DS_STS_APR: turno.CD_STS_APR === '-9' ? lang.no_show :
                      turno.CD_STS_APR === '-3' ? lang.cancelado :
                      turno.CD_STS_APR === '-1' ? lang.encerrado :
                      turno.CD_STS_APR === '1'  ? lang.em_fila :
                      turno.CD_STS_APR === '2'  ? lang.recepcionado :
                      turno.CD_STS_APR === '3'  ? lang.em_operacao :
                      turno.CD_STS_APR === '4'  ? lang.agendado :
                      turno.CD_STS_APR === '5'  ? lang.transito : 'Não agendado',
          PRODUTO_PREDOMINANTE:
                    oc.ITENS.find(item => item.ID_PRO ===
                      oc.ORIGENS.find(origem => origem.NR_CNPJUND === turno.NR_CNPJUND)?.ITENS[0].ID_PRO
                    )?.NM_PRO,
        }))
      })) )
      setLoading(null)
    })

    return true
  }

  useEffect(() => get(), [filtroConcluidas])

  useEffect(()=>{ get()
    setReloader(() => get)
  }, [])

  return(<>
    <c.Frame title={lang.ordem_carregamento} loading={loading} flex
      actions={{
        add: ()=>setIncluir(true),
        filter: {onClick:()=>setShowFilters(!showFilters), value: showFilters},
      }}
      control={<c.Input placeholder={lang.busca} value={search} onChange={({target})=>setSearch(target.value)} clearable />}
    >
      <div className="f g1">
        {'Em aberto'}
        <c.Switch checked={filtroConcluidas} onChange={e => setFiltroConcluidas(e.target.checked)} />
        {'Concluídas'}
      </div>

      <c.Table showFilters={showFilters} hasSub={'SUB'} search={search}
        columns={[
          ['#', 'ID_UOCC'],
          [lang.situacao, '_DS_STS', {}, 'force-fit', 'DS_STS'],
          [lang.data, 'DT_CDT'],
          [lang.tipo, 'TP_FRT'],
          [lang.locais_carregamento, 'LOCAIS_CARREGAMENTO'],
          [lang.peso_carga, 'PESO_CARGA'],
          [lang.capacidade_caminhao, 'CAPACIDADE_VCL'],
          //VD-9855 por Hugo em 03/05/2024
          [lang.aderencia, 'PR_ADERENCIA'],
          ['', 'ACTION', {}, 'force-fit'],
          ['', '', {}, '', 'DS_ITENS'],
          // ['', '', {}, '', 'DS_PRO'],
        ]}
        data={
          data.map(d=>({...d,
            DT_CDT: App.moment(d.DT_CDT).format('DD/MM/YYYY'),
            TP_FRT: d.TP_FRT === 'F' ? 'FOB' : 'CIF',
            LOCAIS_CARREGAMENTO:
              [...new Set( d.ORIGENS?.map(l=>l.NM_PES) )].join(', '),
            CAPACIDADE_VCL: f.formatNumber(d.QT_CAPMAX) + ' ' + d.ID_UNDMOC,
            PESO_CARGA: f.formatNumber(
                          d.ITENS.reduce((acc, i) =>
                            parseFloat(acc) + parseFloat(i.QT_EXP) * parseFloat(i.CONVERSION)
                          , 0)) + ' KG',
            ITENS: d.ITENS.map(i=>({...i,
                      _QUANTIDADE: f.formatNumber(i.QT_EXP) + ' ' + i.ID_UNDMEXP
                    })),
          })).map(d=>{
                //VD-9855 por Hugo em 03/05/2024
                let qt_exp = d.ITENS.reduce((acc, i) => parseFloat(acc) + parseFloat(i.QT_EXP) * parseFloat(i.CONVERSION), 0);
                let pr_aderencia = (qt_exp / d.QT_CAPMAX) * 100;
                //FIM VD-9855
                //VD-9846 - Thales - 12/04/2024
                let ds_sts = d.CD_STS === -3 ? 'Cancelado'
                            :d.CD_STS ===  1 ? 'Pendente'
                            :d.CD_STS ===  2 ? 'Agendado'
                            :d.CD_STS ===  3 ? 'Em coleta'
                            :d.CD_STS === -1 ? 'Concluído' : ''
                if(d.CD_STS > 2 && d.QT_PORTARIAS > 1 && d.QT_PORTARIAS > d.QT_APR_CONCLUIDA){
                  let qt_atual = 1
                  qt_atual += d.QT_APR_CONCLUIDA;
                  ds_sts   += " (" + qt_atual + "/" + d.QT_PORTARIAS + ")";
                }

            // VD-9847 Lucas
            return {...d,
              DS_STS: ds_sts,
              DS_ITENS: d.ITENS.map(i=> i.ID_CN).join(', '),
              DS_PRO: d.ITENS.map(i => i.NM_PRO).join(', '),
              DS_CLIENTE: d.ITENS.map(i => i.NM_CLIENTE).join(', '),
              DS_REF: d.ITENS.map (i => i.CD_EXT).join(', '),
              DS_PORTARIA: d.TURNOS.map(p => p.ID_PRT).join(', '),
              DS_STSPORTARIA: d.TURNOS.map(p => p.DS_STS_APR).join(', '),

              //VD-9855 por Hugo em 03/05/24
              PR_ADERENCIA:
                <div className={['destaque fit-content nowrap', pr_aderencia>90?'success':pr_aderencia<50?'danger':'warning'].join(' ')}>
                  {f.formatNumber(pr_aderencia, 2) + ' %'}
                </div>,

              _DS_STS: <div className={['destaque fit-content nowrap', d.CD_STS===-3?'danger':d.CD_STS===1?'warning':d.CD_STS===2||d.CD_STS===3?'info':'success'].join(' ')}>
                        {ds_sts}
                      </div>,
              ACTION: <div className="center f g1">
                        <DropdownEditMenu setEditing={setEditing} oc={d} control={<icons.BsPencilFill/>} />
                        <c.IconButton onClick={()=>setViewDetails(d)} title="Detalhes">
                          <icons.BsEyeFill />
                        </c.IconButton>
                        <c.IconButton title="Excluir"
                          onClick={() => d.CD_STS === 1
                            ? App.confirm('Confirma a exclusão da OC nº ' + d.ID_UOCC + '?',
                              () => App.api('ordemCarregamento::delete', {idUocc: d.ID_UOCC}).then(r => {
                                if( r.status ){
                                  App.toast.success('OC nº ' + d.ID_UOCC + ' excluída com sucesso!')
                                  get()
                                }
                                return true
                              })
                            ) : App.toast.error('Esta OC já possui agendamento.')
                          } >
                          <icons.BsTrashFill />
                        </c.IconButton>
                      </div>,
                      // VD-9847 Lucas
              SUB:  <div className="f g1 f-column">
                      <c.Table isSub noFooter
                        columns={[
                          [lang.pedido, 'ID_CN'],
                          [lang.cliente, 'NM_CLIENTE'],
                          ["REF", 'CD_EXT'],
                          [lang.produto, 'NM_PRO'],
                          [lang.quantidade, '_QUANTIDADE'],
                        ]} data={d.ITENS} />

                      <c.Divider />

                      <c.Table isSub noFooter
                        columns={[
                          ['','ACTIONS',{},'force-fit'],
                          ['Portaria','_ID_PRT'],
                          ['Produto predominante', 'PRODUTO_PREDOMINANTE'],
                          ['Origem', 'NM_PES'],
                          ['Data e turno', 'DS_DATA_TURNO'],
                          ['Situação', 'DS_STS_APR'],
                        ]}
                        data={d.TURNOS.map(turno => ({...turno,
                          DS_DATA_TURNO: App.moment(turno.DT_CRG).format('DD/MM/YYYY') + ' ' + turno.DS_TRN,
                          _ID_PRT: <div className="f g1 center-v">{turno.ID_PRT}
                                      {!!turno.ID_PRT &&
                                        <c.IconButton title={'Cancelar portaria'}
                                          onClick={() => App.confirm('Cancelar portaria?',
                                            () => App.api('OrdemCarregamento::deletePortaria', {
                                                    turno: turno.ID_UOCC
                                                  }).then(r => r.status && !!get())
                                          )}
                                        ><icons.MdDelete /></c.IconButton>
                                      }
                                    </div>,
                          ACTIONS: <c.IconButton onClick={()=>setAlterarDataTurno(turno)}><icons.MdEdit /></c.IconButton>,
                        }))}
                      />
                    </div>,
            }
        })
      }
    />
    </c.Frame>

    {editing && <OrdemEditar onFinish={()=>get()} onClose={()=>setEditing(null)} data={editing.oc} op={editing.op} />}
    {alterarDataTurno && <AlterarDataTurno turno={alterarDataTurno} onFinish={()=>get()} onClose={()=>setAlterarDataTurno(null)} />}
    {incluir && <OrdemIncluir onFinish={()=>get()} onClose={()=>setIncluir(null)} />}
    {viewDetails && <p.OrdemCarregamentoDetailsModal ordem={viewDetails} onClose={()=>setViewDetails(null)} />}
  </>)
}

function AlterarDataTurno({turno, onClose, onFinish}){
  const App = useContext(AppContext)

  const [date, setDate] = useState(turno.DT_CRG),
        [loading, setLoading] = useState(false)

  function submit(){
    setLoading(true)
    return App.api('ordemCarregamento::changeDataCarregamento',{
      turno_id: turno.ID_UOCC, date
    }).then(r => {
      setLoading(false)
      if( r.status ) onFinish()
      return r.status
    })
  }

  return(
    <c.Modal title={'Alterar data de carregamento'} onClose={onClose} onFinish={submit}
      validate={!!date} loading={loading}
    >
      <c.Input type='date' value={date} onChange={e=>setDate(e.target.value)} />
    </c.Modal>
  )
}

function OrdemIncluir({onFinish, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.ordem_carregamento},
        icons = App.icons

  const [step, setStep] = useState(0),
        [loading, setLoading] = useState(false),
        [searchId, setSearchId] = useState(''),
        [pedidos, setPedidos] = useState([]),
        [clientes, setClientes] = useState([]),
        [produtos, setProdutos] = useState([]),
        [turnos, setTurnos] = useState([]),
        [locais, setLocais] = useState([]),
        [obss, setObss] = useState([]),
        [transportadoras, setTransportadoras] = useState([]),
        [locaisPorProduto, setLocaisPorProduto] = useState([]),
        [turnosPorLocal, setTurnosPorLocal] = useState([]),
        [tmpPedidos, setTmpPedidos] = useState([]),
        [truckCap, setTruckCap] = useState(37000),
        [editing, setEditing] = useState(null),
        [filter, setFilter] = useState({tipo: 'cif'})

  const truckCargaSum = () =>
    tmpPedidos?.reduce((t, p) => t +
      p.itens.reduce((ti, item) => ti + (item.qtSelected??0) * item.CONVERSION, 0)
    , 0)

  const steps = [
    {label: lang.pedidos_itens, validate: truckCargaSum() > 0},
    {label: lang.locais_carregamento,
      validate: ( !locaisPorProduto.length || locaisPorProduto.some(p=>p.locais.some(l=>!!l.qnt)) )
        && locaisPorProduto.every(lpp => lpp.saldo === lpp.locais.reduce((acc,cur) => acc+cur.qnt, 0))
    },
    {label: lang.horarios_turnos,
      validate: turnosPorLocal.every(t =>
        !!t.turno && !!t.date
        && parseInt(t.planejados??0) < parseInt(App.diretivas.QuantidadeOCDiaria)
      )
    },
    {label: lang.transportadoras, validate: !!transportadoras.filter(t=>t.selected).length},
    {label: lang.obs, validate: true},
    {label: lang.resumo, validate: true},
  ]

  useEffect(() => {console.log(tmpPedidos);}, [tmpPedidos])

  //VD-10429 por Hugo em 17/04/2024, essa rotina após atualizar a quantidade de um item em uma determinada OC
  //remonta as quantidades em tmpPedidos, sem a necessidade de reconsultar os registros no banco
  //Está atualizando a quantidade a expedir do item na OC, bem como a quantidade empenhada em estoque e em OC
  function alterarQT_EXP(idPro, novoValor, valorAntigo, idCNOCI) {
    console.log(idPro + " - " + novoValor + " - " + valorAntigo + " - " + idCNOCI);
    setTmpPedidos(prevState => {
      return prevState.map(registro => {
        return {
          ...registro,
          itens: registro.itens.map(item => {
            if (item.PROD_ID === idPro) {
              return {
                ...item,
                qt_oc: (parseInt(item.qt_oc) - parseInt(valorAntigo) + parseInt(novoValor)).toString(),
                stock: (parseInt(item.stock) + parseInt(valorAntigo) - parseInt(novoValor)).toString(),
                oc: item.oc.map(ocItem => {
                  if (ocItem.ID_CNOCI === idCNOCI) {
                    return {
                      ...ocItem,
                      QT_EXP: novoValor,
                      ITENS: ocItem.ITENS.map(ocItemDetalhe => {
                        return {
                          ...ocItemDetalhe,
                          QT_EXP: novoValor
                        };
                      })
                    };
                  } else {
                    return ocItem;
                  }
                })
              };
            } else {
              return {
                ...item,
                oc: item.oc.map(ocItem => {
                  if (ocItem.ID_CNOCI === idCNOCI) {
                    return {
                      ...ocItem,
                      QT_EXP: novoValor,
                      ITENS: ocItem.ITENS.map(ocItemDetalhe => {
                        return {
                          ...ocItemDetalhe,
                          QT_EXP: novoValor
                        };
                      })
                    };
                  } else {
                    return ocItem;
                  }
                })
              };
            }
          })
        };
      });
    });
  }
  //Fim VD-10429

  function get(){
    if(!!tmpPedidos.length) return

    setLoading(true)
    Promise.all([
      App.api('ordemCarregamento::getListas'),
    ]).then(r=>{
      setTmpPedidos(r[0].results['pedidos'])
      setClientes(r[0].results['clientes'])
      setProdutos(r[0].results['pedidos'].reduce((acc, pedido) => {
        pedido.itens.forEach(item => {
          if (!acc.some(p => p.ID === item.PROD_ID)) {
            acc.push({ID: item.PROD_ID, NAME: item.NAME});
          }
        });
        return acc;
      }, []))
      //setLocais(r[0].results['locaisComSaldos'])
      setTurnos(r[0].results['turnos'] ?? [])
      setTransportadoras(r[0].results['transportadoras'])
      setLoading(false)
    }).catch(()=>{
      setLoading(false)
      //App.toast.error('Opss... Houve um erro!')
      onClose()
    })
  }

  async function countOCsByTurno(und, date, turno){
    return !!und && !!date && !!turno
      ? (await App.api('ordemCarregamento::countOCsByTurno', {
          NR_CNPJUND: und,
          DT_CRG: date,
          ID_TRN: turno,
        })).results
      : null
  }

  const submit = () => {
    setLoading(true)

    return App.api('ordemCarregamento::save', {
      truckCap,
      tipo_frete: filter.tipo,
      produtosPorPedido: tmpPedidos.filter(p=>!!p.itens.some(i=>i.qtSelected))
        .flatMap(p=>p.itens.map(i=>({
          pedido_id: p.ID,
          prod_id: i.PROD_ID,
          und_medida: i.UND_MEDIDA,
          conversion: i.CONVERSION,
          qnt: i.qtSelected,
        }))),
      locaisPorProduto:
        locaisPorProduto.filter(p=>p.locais.some(l=>!!l.qnt))
        .flatMap(p=>p.locais.map(l=>({prod_id: p.prod_id, local_id: l.id, qnt: l.qnt})))
        .filter(l=>!!l.qnt),
      turnosPorLocal: turnosPorLocal.map(l=>({
        Local_id: l.id,
        date: l.date,
        turno: l.turno,
      })),
      transportadoras: transportadoras.filter(t=>t.selected).map(t=>t.ID),
      obss
    }).then(r=>{ setLoading(false)
      if(r.status) onFinish()
      return r.status
    })
  }

  useEffect(() => {
    setPedidos( tmpPedidos?.map((p, pid) => {
      const tmp = {...p,
        enabled:
          tmpPedidos.every(tmp=>tmp.itens.every(tmpi=>f.toFloat(tmpi.qtSelected??0) === 0))
          || !tmpPedidos.some(tmp=>
            tmp.TIPO_FRETE!==filter.tipo
            && tmp.itens.some(tmpi=>(tmpi.qtSelected??0) > 0)
          )
          || (filter.tipo==='fob'
              && tmpPedidos.some(tmp=>
                tmp.CNPJ===p.CNPJ && tmp.TIPO_FRETE==='fob'
                && tmp.itens.some(tmpi=>(tmpi.qtSelected??0) > 0)
              )
            ),
        SOLICITADO: p.itens.reduce((t, i)=>t+parseFloat(i.SOLICITADO), 0),
        saldoCalculado: p.itens.reduce((t, i)=>t + parseFloat(i.SOLICITADO), 0) - p.itens.reduce((t, i)=>t+(i.qtSelected??0), 0),

      }

      //VD-9855 POR HUGO EM 10/04/2024
      return {...tmp,
        sub: <c.Table isSub={true} hasSub={'subOc'} columns={[
                ['', 'Stock', {}, 'center-h f force-fit'],
                ['#','PROD_ID'],
                [lang.produto,'NAME'],
                [lang.unidade,'UND_MEDIDA'],
                [lang.saldo,'_saldoCalculado', {}, 'f'],
                [lang.selecionar,'qtSelector', {}, 'center-h f']
              ]}
              data={tmp?.itens?.map((item, item_id)=>({...item,
                saldoCalculado: <span style={{color: item.SOLICITADO - (item.qtSelected??0) < 0 ? 'var(--danger)':''}}>
                                  {parseFloat(item.SOLICITADO) - (item.qtSelected??0)}
                                </span>,
                _saldoCalculado:  <>
                                    <button disabled={parseFloat(item.SOLICITADO) - (item.qtSelected??0) === 0} style={{fontSize:12}} onClick={()=>{
                                      tmpPedidos[pid].itens[item_id].qtSelected = parseFloat(item.SOLICITADO)
                                      setTmpPedidos( [...tmpPedidos] )
                                    }}>
                                      {parseFloat(item.SOLICITADO) - (item.qtSelected??0)}
                                    </button>
                                    <span style={{marginLeft: '5px', color: parseInt(item.stock) < item.qtSelected && 'var(--error)'}}>
                                      { '/' + ((item.stock??0) - (
                                        tmpPedidos.reduce((acc, cur) => acc + cur.itens.filter(_i => parseInt(_i.PROD_ID) === parseInt(item.PROD_ID)).reduce((_acc, _cur) => _acc + (_cur.qtSelected??0), 0) , 0)
                                      ))}
                                    </span>
                                  </>,
                selector: <c.Switch checked={ item.checked } onChange={e => {
                            tmpPedidos[pid].itens[item_id].checked = e.target.checked
                            setTmpPedidos( [...tmpPedidos] )
                          }} />,
                qtSelected: item.qtSelected ?? 0,
                qtSelector: <div className="f g1 center-v">
                              <c.IconButton disabled={(item.qtSelected??0) <= 0} onClick={()=>{
                                tmpPedidos[pid].itens[item_id].qtSelected = (item.qtSelected??0) - 1
                                setTmpPedidos( [...tmpPedidos] )
                              }}>-</c.IconButton>
                              <c.Input fit type='number' value={item.qtSelected??0} style={{width: 50}} onChange={e=>{
                                tmpPedidos[pid].itens[item_id].qtSelected = f.toFloat( Math.min(e.target.value??0, item.SOLICITADO) )
                                setTmpPedidos( [...tmpPedidos] )
                              }} />
                              <c.IconButton disabled={(item.qtSelected??0) >= item.SOLICITADO} onClick={()=>{
                                tmpPedidos[pid].itens[item_id].qtSelected = (item.qtSelected??0) + 1
                                setTmpPedidos( [...tmpPedidos] )
                              }}
                              >+</c.IconButton>
                            </div>,
                delBtn: <c.IconButton onClick={()=>{
                          tmpPedidos[pid].itens[item_id].qtSelected = 0
                          setTmpPedidos( [...tmpPedidos] )
                        }}>
                          <icons.BsTrash />
                        </c.IconButton>,
                //vd-9855 por hugo em 10/04/2024
                //Stock: item.stock < 0 &&<c.Dot blink color={'var(--danger)'} title='Estoque insuficiente' />
                Stock: item.stock < 0 &&<c.Dot blink color={'var(--danger)'} title='Estoque insuficiente' />,
                subOc:
                  <>
                    <div className="f" >
                      <c.Input disabled label={lang.estoque} type="number" value={item.qt_etq} />
                      <c.Input disabled label={lang.ordem_carregamento} type="number" value={item.qt_oc}  />
                      <c.Input disabled label={lang.pedido} value={f.formatNumber(item.qt_ped, 0)}  />
                    </div>
                    <div className="f f-column g1">
                      {/* //VD-10429 por Hugo em 17/04/2024 - Incluído ACTION na tabela abaixo
                      */}
                      {!!item.oc?.length &&
                        <c.Table isSub
                          columns={[
                            ['OC','id'],
                            ['Data','data'],
                            ['Quantidade','quantidade'],
                            ['Situação','situacao'],
                            ['', 'ACTION', {}, 'force-fit'],
                          ]}
                          data={item.oc.map(oc => {
                            const ds_sts = oc.CD_STS == -3 ? 'Cancelado'
                                          :oc.CD_STS ==  1 ? 'Pendente'
                                          :oc.CD_STS ==  2 ? 'Agendado'
                                          :oc.CD_STS ==  3 ? 'Em coleta'
                                          :oc.CD_STS == -1 ? 'Concluído' : ''

                            return {...oc,
                              id: <span >{oc.ID_OC}</span>,
                              data: <span >{oc.DT_OC}</span>,
                              quantidade: <span >{oc.QT_EXP}</span>,
                              situacao: <span >{ds_sts}</span>,
                              ACTION: <div className="center f g1">
                                        <DropdownEditMenu setEditing={setEditing} oc={oc} control={<icons.BsPencilFill/>} />
                                      </div>,
                            }
                          }).filter(oc => ['1', '2', '3'].includes(oc.CD_STS))}
                      />}
                    </div>
                  </>
                //fim vd-9855
              }))}
            />,
          }
    }))
  }, [tmpPedidos, filter])

  useEffect(() => {
    setTransportadoras(
      transportadoras.map(t=>({...t,
        selected: (filter.tipo==='cif' && t.selected)
          || tmpPedidos.filter(p=>p.itens.some(i=>i.qtSelected)).some(p=>p.transportadoras?.some(t1=>t1===t.ID))
      }))
    )
  }, [tmpPedidos])

  useEffect(() => {
    setTurnosPorLocal(
      locaisPorProduto
      .flatMap(produto => produto.locais.map(l=>({...l, prd_name: produto.name, prd_und: produto.und_medida})))
      .filter(item => !!item.qnt)
      .reduce((acc, item) => {
        const acc_id = acc.findIndex(ii => ii.id === item.id)
        const tpl_id = turnosPorLocal.findIndex(ii => ii.id === item.id)
        const value = {
          id: item.id,
          name: item.name,
          produtos: (acc[acc_id]?.produtos??[]).concat(
            [<><b>{item.qnt}</b> x <b>{item.prd_und}</b> {lang.de} <b>{item.prd_name}</b></>],
          ),
          turno: turnosPorLocal[tpl_id]?.turno,
          date: turnosPorLocal[tpl_id]?.date,
        }

        return acc_id >= 0
          ? acc.slice(0, acc_id).concat(value, acc.slice(acc_id + 1))
          : [...acc, value]
      }, [])
    )
  },[locaisPorProduto])

  async function getLocais(){
    setLoading(true)
    setLocais(
      (await App.api('ordemCarregamento::getListasLocaisComSaldos', {
        idsPro: tmpPedidos.reduce((acc, pedido) =>
          acc.concat( pedido.itens.filter(item=>!!item.qtSelected).map(item=>item.PROD_ID) )
        , [])
      })).results
      )
      setLoading(false)
  }

  useEffect(() => {
    setLocaisPorProduto(
      tmpPedidos
      .flatMap(pedido => pedido.itens)
      .filter(item => !!item.qtSelected)
      .reduce((acc, item, item_id) => {
        const acc_id = acc.findIndex(ii => ii.prod_id === item.PROD_ID)
        const lpp_id = locaisPorProduto.findIndex(ii => ii.prod_id === item.PROD_ID)

        let saldo_acc = (acc[acc_id]?.saldo ?? 0) + item.qtSelected

        const value = {
          prod_id: item.PROD_ID,
          name: item.NAME,
          und_medida: item.UND_MEDIDA,
          saldo: (acc[acc_id]?.saldo ?? 0) + item.qtSelected,
          locais: locais.map((local, local_id) => ({
                    id: local.ID,
                    name: local.NAME,
                    stock: local.stock?.find(s => s.ID_PRO === item.PROD_ID)?.QNT ?? 0,
                    qnt: locaisPorProduto[lpp_id]?.locais?.[local_id]?.qnt ?? 0,
                    // qnt: locaisPorProduto[lpp_id]?.locais?.[local_id]?.qnt
                    //   ?? Math.min(
                    //     local.stock?.find(s => s.ID_PRO === item.PROD_ID)?.QNT ?? 0,
                    //     (acc[acc_id]?.saldo ?? 0) + item.qtSelected
                    //   ),
                  }))
                  // Cálculo de quantidades automáticas para os locais baseado no estoque
                  .map(local => {
                    let qnt = Math.max(0, Math.min( local.stock, saldo_acc ) )
                    saldo_acc = saldo_acc - qnt
                    return {...local,
                      qnt: qnt
                    }
                  })
        }

        return acc_id >= 0
          ? acc.slice(0, acc_id).concat(value, acc.slice(acc_id + 1))
          : [...acc, value]
      }, [])
      .map(produto => ({...produto,
        temEstoque: produto.saldo <= produto.locais.reduce((acc, local) => acc + local.stock, 0),
      }))
    )
  }, [locais])

  useEffect(() => {
    if( step === 1 ){
      getLocais()
    }
  }, [step])

  useEffect(() => get(), [])

  return(<>
  <c.Modal largeWidth title={lang.ordem_incluir} steps={steps}
    onStepChange={s=>setStep(s)} loading={loading} onClose={onClose}
    contentHeight={400} onFinish={submit} validate={steps.every(s=>!!s.validate)}
  >
    {/* Pedidos e itens */}
    {step===0 && <div className="f g2">
      <div className="f g1 f-column w50">
        <c.Frame className="filtros" flex>
          <div style={{alignItems: 'baseline'}} className="f g2 w100">

            <c.SwitchButton options={[{value:'cif', label:'CIF'},{value:'fob', label:'FOB'}]} value={filter.tipo} onChange={e=>setFilter({...filter, tipo: e})} />
            <c.Select label={lang.cliente} options={clientes} value={filter.cliente} onChange={e=>setFilter({...filter, cliente: e?.value})} className="f1" clearable />
            <c.Select label={lang.produto} options={produtos}
              value={filter.produto} onChange={e=>setFilter({...filter, produto: e?.value})} className="f1" clearable />

          </div>

          {/* VD-9849 - Samuel - 09/04/2024 */}

            <c.Input className="w100" placeholder={lang.busca} value={searchId} onChange={({target})=>setSearchId(target.value)} clearable />

        </c.Frame>

        <c.Table search={searchId} selectColumns={false} hasSub='sub' rowEnabledField='enabled'
          columns={[
            ['#','ID'],
            [lang.cliente,'CLIENTE'],
            ['REF', 'CD_EXT'],
            [lang.data_cadastro,'_DT_CN'],
            [lang.data_prevista,'_DT_PRV'],
            [lang.destino,'DESTINO'],
            [lang.saldo,'saldoCalculado',{},'center-h'],
            ['TAG','_ID_CC',{},'f g1'],

          ]}

          data={(pedidos??[]).filter(p =>
            p.TIPO_FRETE === filter.tipo
            && (!!filter.cliente ? p.CNPJ === filter.cliente : true)
            && (!!filter.produto ? p.itens.some(i=>i.PROD_ID === filter.produto) : true)
          ).map(p => ({...p,
            CD_EXT: (p.CD_EXT ?? 0) === 0 ? '' : (p.CD_EXT ?? 0),
            _DT_PRV: App.moment(p.DT_PRV).format("DD/MM/YYYY"),
            _DT_CN: App.moment(p.DT_CN).format("DD/MM/YYYY"),
            _ID_CC:p.COOPERANTE == '1' ?  <>
            <button className={['primary','nowrap'].join(' ')}
              style={{fontSize: 12, padding: '2px 5px', maxWidth: 'initial', flex: 1,backgroundColor:'#00a38a'}}
              disabled={false}
              title={'Cooperante'}
              >Cooperante</button>
            </> : <></>
          }))}
        />
      </div>

      <div className="f g1 f-column w50">
        {truckCargaSum() > truckCap &&
          <div className="destaque danger p2 center-h">Capacidade do caminhão excedida!</div>
        }

        <div className="truckCap f g2 f-between center-v p1">
          <c.Input type='number' label={lang.capacidade_caminhao} value={f.formatNumber(truckCap)} onChange={e=>setTruckCap(f.toFloat(e.target.value))} className='f1' />
          <c.Span  label={lang.capacidade_realizada} value={f.formatNumber(truckCargaSum())} className='f1' />
        </div>

        <div className="f g2 f-start">

          <c.Frame title={lang.selecionados} className="f1">

            {pedidos?.filter(p=>p.itens.some(item=>(item.qtSelected??0)>0)).map(p=>
              <div key={p.ID} style={{borderBottom: '1px dashed #ccc'}}>
                <div className="b p2">{'#'}{p.ID} - {p.CLIENTE}</div>
                <c.Table isSub noHeader columns={[
                  ['','descricao'],
                  ['','delBtn', {}, 'force-fit'],
                ]}
                  data={p.itens.filter(item=>(item.qtSelected??0) > 0)
                    .map(i=>({...i,
                      descricao: i.qtSelected + ' ' + i.UND_MEDIDA + ' '+ lang.de+' ' + i.NAME
                    }))
                  }
                />

              </div>
            )}

            {!pedidos?.filter(p=>p.itens.some(item=>(item.qtSelected??0)>0)).length && lang.selecione_item}
          </c.Frame>

          <TruckCharging load={truckCargaSum()/truckCap*100} />
        </div>
      </div>
    </div>}

    {/* Locais de carga */}
    {step===1 &&
      <c.Table isSub emptyMsg={lang.msg_produtos_tela}
        columns={[
          [lang.produto,'_name',{},'f g1 center-v'],
          [lang.unidade,'und_medida'],
          [lang.saldo,'saldo',{},'center-h'],
          [lang.selecionar,'saldoCalculado',{},'center-h']
        ].concat(locais.map(l=>[
          l.NAME + ' / ' + lang.estoque,
          'l_'+l.NAME.replace(/[^\w\s]|[\s]/gi, "").toLowerCase(),
          {},
          'f g1 center-v'
        ]))}
        data={
          locaisPorProduto
          .map(p=>({...p,
            saldoCalculado: p.saldo - p.locais.reduce((t,local)=>t + parseInt(local.qnt??0), 0)??0,
            ...p.locais.reduce((t,l,l_id)=>({...t,
              ['l_' + l?.name?.replace(/[^\w\s]|[\s]/gi, "").toLowerCase()]:
                <>
                  <c.Input fit value={l.qnt??0} style={{width: 70}}
                    onChange={e=>{
                      p.locais[l_id].qnt = f.toFloat( Math.min(e.target.value, p.saldo) )
                      setLocaisPorProduto([...locaisPorProduto])
                    }}
                  />
                  / <span title={"Estoque de " + p.name + " em " + l.name}>{l.stock}</span>
                </>,
            }),{})
          }))
          .map(p => ({...p,
            _name: <>{p.name} {!p.temEstoque && <c.Dot blink color={'var(--danger)'} title='Estoque insuficiente' />}</>,
            saldoCalculado: <span style={{color: p.saldoCalculado < 0 ? 'var(--danger)':''}}>
                              {p.saldoCalculado}
                            </span>,
          }))
        } />
    }

    {/* Horários e turnos */}
    {step===2 &&
      <c.Table
        columns={[
          [lang.local,'name'],
          [lang.produtos,'produtos'],
          [lang.data,'dtSelect'],
          [lang.turno,'turnoSelect'],
          [lang.planejados,'_planejados'],
        ]}
        data={turnosPorLocal.map((l, local_id) => ({...l,
          produtos: l.produtos?.filter(p=>!!p).reduce((t,i)=><>{!!t?<>{t}<br/></>:''}{i}</>,''),
          dtSelect: <c.Input type='date' value={l.date} error={!l.date} className='force-fit'
                      onChange={async e => {
                        l.date = e.target.value
                        l.planejados = await countOCsByTurno(l.id, l.date, l.turno)
                        setTurnosPorLocal([...turnosPorLocal])
                      }} />,
          turnoSelect: <c.Select value={l.turno} error={!l.turno}
                          options={turnos?.flatMap((t=>({label: t.NAME, value: t.ID})))??[]}
                          onChange={async e => {
                            l.turno = e.value
                            l.planejados = await countOCsByTurno(l.id, l.date, l.turno)
                            setTurnosPorLocal([...turnosPorLocal])
                          }} />,
          _planejados: (parseInt(l.planejados??0)) < parseInt(App.diretivas.QuantidadeOCDiaria)
                        ? l.planejados
                        : <span className="f center-v t-danger g2" title="Limite de OC dária para unidade excedido!">
                            {l.planejados} <icons.MdOutlineCancel />
                          </span>,
        }))}
      />}

      {/* Transportadoras */}
      {step===3 &&
        <c.Select multi clearable searchable options={transportadoras.map(t=>({value: t.ID, label: t.NAME}))}
          label={lang.transportadoras}
          value={transportadoras.filter(t=>t.selected).map(t=>t.ID)}
          onChange={e=>setTransportadoras([...transportadoras.map(t=>({...t, selected: e.some(tt=>tt.value===t.ID)}))])}
        />
      }

      {/* Observações */}
      {step===4 &&
        <div className="f g1 f-column w100">
          <button title={lang.adicionar} onClick={()=>setObss([...obss.filter(o=>!!o.ds_obs), {}])}>
            <icons.MdAdd />{lang.adicionar}
          </button>
          {obss?.map((o,i)=>
            <div key={i} className="f g1">
              <c.Select label={lang.tipo} className=""
                options={['success','danger','warning','info'].map(op=>({value: op, label: lang[op], color: 'var(--'+op+')'}))}
                styles={{option:(s, {data})=>({...s, color: 'var(--white)', background: data.color})}}
                onChange={e=>{o.id_color=e.value; setObss([...obss])}}
                value={o.id_color} style={{flexBasis: 150}}
              />
              <c.Input value={o.ds_obs??''} label={lang.observacao} className="f4"
                onChange={e=>{o.ds_obs=e.target.value; setObss([...obss])}}
                inputStyle={{borderLeft: '5px solid var(--'+(o.id_color??'color')+')'}}
              />
              <button onClick={()=>{obss.splice(i,1);setObss([...obss])}}><icons.BsFillTrashFill /></button>
            </div>
          )}
        </div>
      }

      {/* Resumo */}
      {step===5 && <div className="f g2">
        <div className="f g2 f-column w50">
          {/* Resumo Pedidos, clientes e itens */}
          <c.Frame title={lang.pedidos_cliente_itens} className="w100">
            <c.Table isSub
              data={
                pedidos
                .filter(p=>p.itens.some(item=>(item.qtSelected??0)>0))
                .map(p=>({...p,
                  produtos:
                    p.itens
                    .filter(i => (i.qtSelected??0) > 0)
                    .reduce((t, item) =>
                      <>{!!t ? <>{t}<br/></> : ''}
                        <b>{f.formatNumber(item.qtSelected??0)}</b> x <b>{item.UND_MEDIDA}</b> {lang.de} <b>{item.NAME}</b>
                      </>
                    ,'')
                }))
              } columns={[
                ['#','ID'],
                [lang.cliente,'CLIENTE'],
                [lang.produtos,'produtos'],
              ]} />
          </c.Frame>

          {/* Resumo transportadoras */}
          <c.Frame title={lang.transportadoras} className="w100">
            {!!transportadoras.filter(t=>t.selected).length
              ? transportadoras.filter(t=>t.selected).map(t=>t.NAME).join(', ')
              : lang.msg_selec_transp
            }
          </c.Frame>
        </div>

        <div className="f g2 f-column w50">
          {/* Resumo Locais, produtos e turnos */}
          <c.Frame title={lang.loc_prod_turn} className="w100">
            <c.Table isSub
              data={
                turnosPorLocal
                .map(l=>({...l,
                  produtos: l.produtos?.filter(p=>!!p)
                    .reduce((t,i)=><>{!!t?<>{t}<br/></>:''}{i}</>,''),
                  dtTurno: !!l.date && !!l.turno
                    ? App.moment(l.date).format('DD/MM/YYYY') + ' '
                      + turnos.find(t=>t.ID===l.turno)?.NAME
                    : <span className="destaque danger nowrap">Veja a aba "Horários/turnos"</span>
                }))
              } columns={[
                [lang.local, 'name'],
                [lang.produtos, 'produtos'],
                [lang.data_e_turno, 'dtTurno'],
              ]} />
          </c.Frame>

          {/* Resumo Observações */}
          <c.Frame title={lang.obs} className="w100">
            {!obss.length
              ? lang.nothing_here
              : <div className="f g1 f-column">
                  {obss?.map((obs, i)=>
                    <div key={i} style={{
                      borderLeftStyle: 'solid', borderWidth: 5,
                      borderColor: 'var(--'+obs.id_color+')',
                    }} className="p1">
                      {obs.ds_obs}
                    </div>
                  )}
                </div>
            }
          </c.Frame>
        </div>
      </div>}
  </c.Modal>
  {/* //VD-10429 por Hugo em 17/04/2024 incluído a função alterarQT_EXP
  */}
  {editing && <OrdemEditar onFinish={()=>get()} onClose={()=>setEditing(null)} data={editing.oc} op={editing.op} alterarQT_EXP={alterarQT_EXP} />}
  </>)
}

//VD-10429 por Hugo em 17/04/2024 incluído a função alterarQT_EXP
function OrdemEditar({onFinish, onClose,data,op,alterarQT_EXP}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.ordem_carregamento},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [transportadoras, setTransportadoras] = useState([]),
        [dataTransp,setDataTransp] = useState({
          transportadoras: data.TRANSPORTADORAS
          .filter((t) => t.CD_STS != '-3')
          .map((t) => t.NR_CNPJTRP),
          lst_transp:        data.TRANSPORTADORAS.map(t=>({label: t.NM_TRP, value: t.NR_CNPJTRP}))
        }),
        [dataShow,setDataShow] = useState({...data,ITENS: data.ITENS.map(i => ({
          ...i,
          QT_EXPA: i.QT_EXP
        }))})

  const columns = [
    ["#","ID_PRO"],
    ["Pedido","ID_CN"],
    ["Produto", "NM_PRO"],
    ["Quantidade", "INPUT"],
    ["UN. de medida","ID_UNDMEXP"]
  ];

  function get(){
    if(op == 'transportadora'){
      setLoading(true)
      App.api('ordemCarregamento::getListasTransportadoras').then(r=>{
        setTransportadoras(r.results.map((v,i)=>({value: v.ID, label: v.NAME, key: i})))
        setLoading(false)
      })
    }
    if(op === 'pedido'){
      let chaves = []
      data.ITENS.forEach((item)=>{
          var chave = item['P_CN'] + "|" + item['P_DT'] + "|" + item['P_UCN']
          chaves.push(chave)
      })
      var r_duplicates = new Set(chaves)
      chaves = [...r_duplicates]
      const pedidos_list = chaves.map(str =>{
        const [P_CN, P_DT, P_UCN] = str.split('|');
        return {P_CN, P_DT, P_UCN}
      })
      App.api('ordemCarregamento::getSolicitado',{pedidos: pedidos_list}).then(r=>{
        setDataShow(agrupa(r.results, dataShow))
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    get()
  },[])

  function submit(){
    setLoading(true)
    if(op==='transportadora'){
      return App.api('ordemCarregamento::editTransportadora', {
        ID_UOCC: data.ID_UOCC,
        DT_CDT: data.DT_CDT,
        TRANSPORTADORAS: dataTransp.transportadoras
      }).then(r=>{ setLoading(false)
        if(r.status) onFinish()
        setLoading(false)
        return r.status
      })
    }
    else if(op==='pedido'){
      return App.api('ordemCarregamento::updateItemOC', {
        ITENS: dataShow.ITENS
      }).then(r=>{ setLoading(false)
        if(r.status) onFinish()
        setLoading(false)
        //VD-10429 por Hugo em 17/04/2024 ao confirmar a edição da quantidade, altera as quantidades na tmpPedidos
        alterarQT_EXP(dataShow.ITENS[0].ID_PRO, dataShow.ITENS[0].QT_EXP, dataShow.ITENS[0].QT_EXPA, dataShow.ITENS[0].ID_UOCC)
        return r.status
      })
    }
    else if(op==='obs'){
      return App.api('ordemCarregamento::updateOBS', {
        DS_OBS: dataShow.OBSS,
        ID_UOCC: dataShow.ID_UOCC
      }).then(r=>{ setLoading(false)
        if(r.status) onFinish()
        setLoading(false)
        return r.status
      })
    }

  }

  const validate = [
    {
      validate: op == "pedido"? f.validateOCItem(dataShow.ITENS) : true
    }
  ]

  return(
    <c.Modal title={lang.editando_oc + dataShow.ID_UOCC + ' - ' + lang[op]}
      validate={validate.every(s=>s.validate)} loading={loading} onClose={onClose} onFinish={submit}
      extraControls={!(op==='obs')?null:
        <button title={lang.adicionar} onClick={()=>{
          dataShow.OBSS=dataShow.OBSS.filter(o=>!!o.ds_obs);dataShow.OBSS.push({ds_obs:''});
          setDataShow({...dataShow})}}><icons.MdAdd />{lang.adicionar}</button>
      }
    >
    {op==='transportadora' &&
      <div style={{minHeight: 400}}>
        <c.Select multi label={lang.transportadora} options={transportadoras} value={dataTransp.transportadoras} style={{width: '70vw'}}
          defaultValue={dataTransp.lst_transp} clearable searchable onChange={e => setDataTransp({...dataTransp,transportadoras:e.map(t=>t.value)})}/>
      </div>
    }
    {op==='pedido'&&
      <div style={{minHeight: 400}}>
        <c.Table
          columns={columns}
          data={dataShow.ITENS.map((d,i) =>({
              ...d,
              INPUT:
                <c.Input
                  fit
                  value={d.QT_EXP}
                  type="number"
                  className="w50"
                  onChange={(e) =>{
                    setDataShow({
                      ...dataShow,
                      ITENS: [...dataShow.ITENS.slice(0, i), {...d,QT_EXP:e.target.value}, ...dataShow.ITENS.slice(i + 1)]
                    })
                  }}
                  error={(parseInt(d.QT_EXP) > parseInt(d.RESTANTES) + parseInt(d.QT_EXPA)) || (!d.QT_EXP)}
                />
            }))
          }
          loading={loading}
        />
      </div>
    }
    {op==='obs' &&
      <div className="f g1 f-column" style={{width: '70vw'}}>
        {dataShow.OBSS?.map((o,i)=>
          <div key={i} className="f g1">
            <c.Select label={lang.tipo} className="f1" options={[
                {value: 'success', label: lang.success, color: 'var(--success)'},
                {value: 'danger',  label: lang.danger,  color: 'var(--danger)'},
                {value: 'warning', label: lang.warning, color: 'var(--warning)'},
                {value: 'info',    label: lang.info,    color: 'var(--info)'},
              ]} styles={{option:(s, {data})=>({...s, color: 'var(--white)',background: data.color})}}
              onChange={e=>{
                setDataShow({
                  ...dataShow,
                  OBSS: [...dataShow.OBSS.slice(0, i), {...o,id_color:e.value}, ...dataShow.OBSS.slice(i + 1)]
                })

              }}
              value={o.id_color}
            />
            <c.Input value={o.ds_obs} label={lang.observacao} className="f4"
              onChange={e=>{
                setDataShow({
                  ...dataShow,
                  OBSS: [...dataShow.OBSS.slice(0, i), {...o,ds_obs:e.target.value}, ...dataShow.OBSS.slice(i + 1)]
                })
              }}
              inputStyle={{borderLeft: '5px solid var(--'+(o.id_color??'color')+')'}}
            />
            <button onClick={()=>{
              setDataShow({
                ...dataShow,
                OBSS: [...dataShow.OBSS.slice(0, i), ...dataShow.OBSS.slice(i + 1)]
              })
            }}><icons.BsFillTrashFill /></button>
          </div>
        )}
      </div>
    }

    </c.Modal>
  );
}

function DropdownEditMenu({oc, control, setEditing}){
  console.log(oc);
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.ordem_carregamento}

  return(
    <c.DropdownMenu
      itens={[
        {title: lang.transportadora,   action: ()=>setEditing({oc,op:'transportadora'}), disabled: oc.CD_STS == -3 || oc.CD_STS == -1 },
        {title: lang.pedido,           action: ()=>setEditing({oc,op:'pedido'}),         disabled: oc.CD_STS == -3 || oc.CD_STS == -1 || oc.CD_STS == 3 },
        {title: lang.obs,              action: ()=>setEditing({oc,op:'obs'}),            disabled: oc.CD_STS == -3 || oc.CD_STS == -1 || oc.CD_STS == 3 },
      ]}
      title={lang.editar}
    >
      {control}
    </c.DropdownMenu>
  )
}

function agrupa(restantes,data){
  restantes.forEach(r => {
    data.ITENS.forEach(d => {
      if(r.ID_PRO == d.ID_PRO && r.P_UCN == d.P_UCN && r.P_DT == d.P_DT && r.P_CN == d.P_CN){
        d['RESTANTES'] = r.RESTANTE
      }
    })
  })
  return data
}
