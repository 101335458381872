import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppContext } from '../App'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import RSelect from 'react-select'
import makeAnimated from 'react-select/animated'

import * as Flags from 'country-flag-icons/react/3x2'
import * as f from '../f'
import { IconButton } from './IconButton'

export { default as Table } from './table'
export { default as DaterangePicker } from './daterangePicker'
export { default as Modal } from './modal'
export { default as Upload } from './upload'
export { default as PreviewPDF } from './previewPDF'

// Velog Especials
export { PortariaDownloadButtons } from './velog'
export { PedidoDownloadButtons } from './velog' // VD-9853 - Thales - 12/04/2024

export function Input(props){
  const App = useContext(AppContext),
        icons = App.icons

  const [showPass, setShowPass] = useState(false)

  const validate =
    (props.minLength && (props.value.length > props.minLength)) || !props.minLength

  return(
    <label className={[
      'text-group', props.className, props.inline?'inline':'',
      !!props.icon||!!props.clearable||(props.type==='password')||props.onSearch?'icon':'',
      props.error||(props.required&&!props.value)?'error':'',
      props.fit?'fit':'',
      ].join(' ')}
      style={props.style}
    >
      {props.loading
        ? <Skeleton />
        : <>
          <input type={props.type==='password'&&showPass?'text':(props.type||'text')}
            placeholder={props.placeholder??props.label} value={props.value} onBlur={props.onBlur}
            min={props.min} max={props.max}
            onChange={props.onChange??(()=>{})} minLength={props.minLength} maxLength={props.maxLength}
            onKeyPress={e=>e.key==='Enter'&&props.iconClick&&props.iconClick()} disabled={props.disabled}
            style={props.inputStyle} onKeyDown={props.onKeyDown}
          />
          {!!props.icon &&
            <IconButton onClick={props.iconClick} style={{display:!!props.value?'block':'none'}}>
              {props.icon==='search' && icons.MdSearch({size: 24})}
            </IconButton>
          }
          {props.clearable && !!props.value &&
            <IconButton onClick={()=>props.onChange({target:{value:''}})}>
              {icons.MdClear({size: 24})}
            </IconButton>
          }
          {!!props.onSearch && !!props.value &&
            <IconButton onClick={props.onSearch}>
              {icons.MdSearch({size: 24})}
            </IconButton>
          }
          {props.type==='password' &&
            <IconButton onClick={()=>setShowPass(!showPass)}>
              {showPass ? icons.BsEyeSlashFill({size: 24}) : icons.BsEyeFill({size: 24})}
            </IconButton>
          }
        </>
      }

      {props.label && <span className="label">{props.label}</span> }

      {(!!props.info || !!props.minLength) &&
        <span className={'info'+ (!validate||props.error?' danger':'')}>
          {!!props.info && <>{props.info}<br /></>}
          {!!props.minLength ? <><span>Mínimo de {props.minLength} caracteres</span><br /></>:''}
        </span>
      }

    </label>
  )
}

export function Chat({user, msg, height= 130}) {
  const messageContainer = useRef(null)
  const [validate, setValidate] = useState(0)
  const [scroll, setScroll] = useState(false)

  function goScrollTop() {
    if(messageContainer.current) {
      const {scrollHeight, clientHeight} = messageContainer.current
      messageContainer.current.scrollTop = scrollHeight - clientHeight

    }
  }

  useEffect( () => {
    goScrollTop()
  }
  , [msg])

  useEffect(() => {
    validate > 0 ? setScroll(true) : setScroll(false)

  }, [validate])

  return (

  <div className='chat f g1 f-column' style={{maxHeight: height + 'px'}} onScroll={ (e) => (setValidate(e.target.scrollTop))} ref={messageContainer}>
  <div className={scroll ? 'cloud ' : ''} ></div>
  {msg ? msg?.map((o, i) => (
    <>
    {user === o.ID_OPR ?
      (<div key={i} className="f g1" style={{justifyContent: 'right', marginRight: '10px'}}>
            <span style={{textAlign: 'right',
            borderRight:"5px solid var(--" + (o.DS_OBS.id_color ?? "color") +")",
            background: 'var(--white)'}}
            className='message'>
              {o.DS_OBS.ds_obs}
              <span style={{right: 0}} className='time'>
                {o.ID_LOGIN + o.DT_OPR}
              </span>
            </span>
            </div>
         ) : (<div key={i} className="f g1" style={{ marginLeft: '10px'}}>
          <span style={{textAlign: 'left',
                        borderLeft: "5px solid var(--" + (o.DS_OBS.id_color ?? "color") + ")"}}
                className='message'>
                {o.DS_OBS.ds_obs}
                  <span style={{left: 0}} className='time'>
                    {o.ID_LOGIN + o.DT_OPR}
                  </span>
              </span></div>)
        }
        </>
  )) : (<h1>SEM DADOS!</h1>)}
  </div>)

}

export function Container({children, className, ...rest}){
  return(
    <div className={[className, 'container'].join(' ')} {...rest}>{children}</div>
  )
}

export function Col({children, className, ...rest}){
  return(
    <div className={[className, 'col'].join(' ')} {...rest}>
      {children}
    </div>
  )
}

export function Row({children, className, ...rest}){
  return(
    <div className={[className, 'row'].join(' ')} {...rest}>
      {children}
    </div>
  )
}

export function Avatar({size='48', border=null, ...rest}){
  const App = useContext(AppContext)
  let avatar = null

  try{
    avatar = require('../a/avatars/' + size + '/' + App.cliente.id + '.png')
  }catch(e){
    avatar = require('../a/avatars/' + size + '/default.png')
  }

  return(
    <div className={['avatar','s'+size, border?'border':''].join(' ')} {...rest}>
      <img src={avatar??null} width={size} height={size} alt='Avatar'/>
    </div>
  )
}

export function Sidebar({itens, selection, setSelection, setReloading, className='', ...rest}){
  const App = useContext(AppContext),
        icons = App.icons

  return(
    <ul className={'menu ' + className} {...rest}>
      {itens.map((m, mi) => m.allow &&
        <li key={mi} className={ parseInt(selection[0]) === mi ? 'active':''}>
          <span onClick={()=>setSelection([mi, 0])}>
            <span>
              <span className='menu-icon'>{m.icon()}</span>
              <span className='menu-name'>{m.title}</span>
            </span>
            {m.hasReloader&&parseInt(selection[0]) === mi&&
              <IconButton onClick={()=>setReloading(true)}><icons.MdRefresh/></IconButton>}
          </span>
          {m.itens ?
            <ul className='submenu'>
              {m.itens.map((s, si) => s.allow &&
                <li key={si} className={ parseInt(selection[0]) === mi && parseInt(selection[1]) === si ? 'active':''}>
                  <span onClick={()=>setSelection([mi, si])}>
                    <span>
                      {s.icon  && <span className='menu-icon'>{s.icon()}</span>}
                      {!s.icon && <>
                        <span className='menu-icon dot-active'>{App.icons.BsDot()}</span>
                        <span className='menu-icon dot-inactive'></span>
                      </>}
                      <span className='menu-name'>{s.title}</span>
                    </span>
                    {!!s.hasReloader&&(parseInt(selection[0]) === mi && parseInt(selection[1]) === si)&&
                      <IconButton onClick={()=>setReloading(true)}><icons.MdRefresh/></IconButton>}
                  </span>
                </li>)}
            </ul>
          : null}
        </li>
      )}
    </ul>
  )
}

export function DropdownMenu({
  children,
  itens = [],
  title = null,
  style = {},
  direction = 'right',
  ...rest
}) {
  const App = useContext(AppContext)
  const icons = App.icons

  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [menuRef])

  function adjustMenuPosition() {
    const menuElement = menuRef.current
    const rect = menuElement.getBoundingClientRect()

    if (rect.x + rect.width + 20 > window.innerWidth) {
      const marginLeft = rect.left + rect.width - window.innerWidth + 60
      menuElement.style.marginLeft = `-${marginLeft}px`
    }
  }

  useEffect(() => {
    if (open) adjustMenuPosition()
  }, [open])

  return (
    <div className="dropdown-menu" title={title} {...rest}>
      <div
        onClick={() => setOpen(!open)}
        className='dropdown-menu-clicker f center-h'
      >
        {children}
      </div>
      {open && (
        <ul
          ref={menuRef}
          className={itens.some(i => !!i.icon)?'hasIcon':''}
          style={style}
        >
          {itens.map((item, index) => (
            !!item.title && !item.hide &&
            <li key={index} className={item.disabled ? 'disabled' : ''} >
              <div
                className="menu-item"
                onClick={() => {
                  if (item.disabled) return

                  item.action();
                  setOpen(false);
                }}
              >
                {item.icon}
                {item.title}
                <span className="submenu-icon">
                  {(item.itens?.length ?? 0) > 0 && (
                    <icons.MdOutlineArrowRight />
                  )}
                </span>
              </div>
              {item.itens && !item.disabled && (
                <ul className={`sub-menu ${direction}`} >
                  {item.itens.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={(item.disabled || subItem.disabled) ? 'disabled' : ''}
                      onClick={() => {
                        if (item.disabled || subItem.disabled) return

                        subItem.action();
                        setOpen(false);
                      }}
                    >
                      {subItem.title}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function Card({type='normal', icon, title, value=null, percent=null, ...rest}){
  return(<div className={['card',type].join(' ')} {...rest}>
    <div className='card-content'>
      <span className='card-icon'>{icon({size:'24'})}</span>
      <span className="card-value" >{value!==null ? value : <Skeleton />}</span>
    </div>
    <div className='card-title'>
      {!!percent && <span className={'card-percent '+(percent>=0?'plus':'minus')}>{percent}%</span>}
      {title || <Skeleton />}
    </div>
  </div>)
}

export function NotificationMenu({}) {
  const App = useContext(AppContext),
        icons = App.icons,
        notifications = App.notifications

  const [open, setOpen] = useState(false)

  const menuRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(e) {
      if (menuRef.current && !menuRef.current.contains(e.target)) setOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [menuRef])

  function adjustMenuPosition() {
    const menuElement = menuRef.current
    const rect = menuElement.getBoundingClientRect()
    if( rect.x + rect.width + 10 > window.innerWidth ) menuElement.style.left = `${window.innerWidth - rect.width - 10}px`
  }

  function handleOpen() {
    setOpen((prevState)=>!prevState)
  }

  function changeStatus(status, id=null){
    if (notifications.itens?.length) {
      App.api('notifications::setStatus',{
        CD_STS: status,
        ID_UOCC_list: id ? [id] : notifications.itens?.map(i=>i.ID_UOCC)
      }).then(r=>r.status&&App.setApp({...App, notifications: r.results}))
    }
  }

  useEffect(() => {
    if (open) {
      adjustMenuPosition()
      //changeStatus(1)
    }
  }, [open])

  return(
    <div className="dropdown-menu notifications">
      <IconButton onClick={()=>handleOpen()} className='dropdown-menu-clicker'>
        {!!notifications.noreads
          ? <Badge value={notifications.noreads}>
              <icons.MdNotifications size={24} />
            </Badge>
          : <icons.MdNotificationsNone size={24} />
        }
      </IconButton>
      {open &&
        <ul ref={menuRef}>
          {notifications.itens?.map((item, i) => (
            <li key={i} onClick={()=>{ changeStatus(1, item.ID_UOCC) }}
              style={{minWidth: 500}} className={item.action?'action':''}
            >
              <div style={{minWidth: 20}}>
                  {item.CD_STS === '0'
                    ? <span><icons.FaEnvelope size={16} style={{color: 'var(--warning)'}} /></span>
                    : <span><icons.FaEnvelopeOpen size={16} style={{color: 'var(--success)'}} /></span>
                  }
              </div>
              <div className='notification-info'>
                <span className={["notification-msg", (item.CD_STS === '0' ? 'notification-msg__unread' : '')].join(' ')}>
                  {item.DS_MSG}
                </span>
                <span className='notification-date'>{f.formatDateExtension(item.DT_CDT)}</span>
              </div>
              <div className='notification-delete'>
                <IconButton onClick={(event)=>{
                  event.stopPropagation()
                  changeStatus(2, item.ID_UOCC)
                }}><icons.MdClose /></IconButton>
              </div>
            </li>
          ))}
          {!notifications.itens?.length && <li className='center disabled'>{App.lang.global.nothing_here}</li>}
        </ul>
      }
    </div>
  )
}

export function Badge({children, value='', style={}, className='', variant='info'}){
  return(<div className={['badge', className].join(' ')}>
    <span className='badge-value' style={{background: 'var(--'+variant+')', ...style}}>{value}</span>
    {children}
  </div>)
}

export function Select({
  className = '',
  clearable = false,
  defaultValue = null,
  error = null,
  fit,
  hideDropdownButton = false,
  isDisabled = false,
  label = null,
  loading,
  multi = false,
  onChange,
  onInputChange = () => {},
  options: initialOptions,
  placeholder = null,
  required = false,
  searchable = false,
  style,
  styles = {},
  value: initialValue = null,
  ...rest
}){
  const App = useContext(AppContext),
        lang = App.lang.global

  const options = useMemo(() => {
    if(!initialOptions) return []

    return initialOptions.map((option, index) => {
      return {
        value: option.value ?? option.VALUE ?? option.id ?? option.ID ?? index,
        label: option.label ?? option.LABEL ?? option.name ?? option.NAME ?? option,
      }
    })
  }, [initialOptions])

  const labelClassName = useMemo(() => {
    return [
      'text-group',
      className,
      (error || (required && !initialValue)) ? 'error' : '',
      fit ? 'fit' : ''
    ].join(' ')
  }, [className, error, fit, initialValue, required])

  const value = useMemo(() => {
    if(multi) {
      return options.filter(
        (option) => (initialValue ?? []).indexOf(option.value) >= 0
      )
    }

    return !!initialValue && options.find(
      (option) => option.value === initialValue
    )
  }, [initialValue, multi, options])

  return (
    <label
      className={labelClassName}
      style={style}
      {...rest}
    >
      {loading
        ? <Skeleton />
        : (
          <RSelect
            options={options}
            value={value}
            onChange={onChange}
            noOptionsMessage={()=><span>{lang.sem_opcoes}</span>}
            isClearable={clearable}
            defaultValue={defaultValue}
            components={{...makeAnimated(), ...hideDropdownButton && { DropdownIndicator:() => null, IndicatorSeparator:() => null }}}
            menuPortalTarget={document.body}
            onInputChange={onInputChange}
            isSearchable={searchable}
            isMulti={multi}
            placeholder={placeholder ?? label}
            isDisabled={isDisabled}
            classNamePrefix="select-control"
            styles={{
              control: (s)=>({...s, border: '1px solid var(--color)'}),
              menu: (b)=>({...b, border: '1px solid var(--color)', padding: 0, }),
              menuPortal: (base) => ({...base, padding: 0, zIndex:100, marginTop: '-10px', }),
              option: (provided, state)=>({
                ...provided,
                fontSize: 12,
                padding: 'var(--gap)',
                backgroundColor: state.isFocused ? 'var(--colorOff) !important' : '',
                color: state.isFocused ? 'var(--colorText) !important' : '',
              }),
              ...styles
            }}
          />
        )
      }
      {label && <span className="label">{label}</span>}
    </label>
  )
}

export function Divider({text=null}){
  return(
    <div className='divider'>
      <div className='divider-line'></div>
      {!!text && <>
        <div className='divider-text'>{text}</div>
        <div className='divider-line'></div>
      </>}
    </div>
  )
}

export function TelefoneView({value=null, width=20}) {
  const App = useContext(AppContext)

  if (value === null) return ''

  // VD-15623, Lucas, 24/06/2024
  const Flag=()=>
    value.substr(0, 1) === '54' ? <Flags.AR style={{ width: width }} />
      : value.substr(0, 2) === '595' ? <Flags.PY style={{ width: width }} />
      : value.substr(0, 2) === '598' ? <Flags.UY style={{ width: width }} />
      : <Flags.BR style={{width: width }} />

  return (<><Flag /> {f.formatTel(value).split(' ').slice(1).join(' ')}</>)
  //return (<> {f.formatTel(value)}</>)
}

export function Flag({ code }) {
  const FlagIcon = Flags[code]
  return <FlagIcon className='flagIcon' />
}

export function Span({label, value, className='', style={}, sub=null, fit=null, title=''}){
  return(
    <div title={title}
      className={[
        'span',
        className,
        fit?'fit':'',
      ].join(' ')} style={style}
    >
      <div className={['span-value', !!className?.includes('ellipsis') ? 'ellipsis' : ''].join(' ')}>
        {value} {!!sub&&<span className='span-value-sub'>{sub}</span>}
      </div>
      <div className='span-label'>{label}</div>
    </div>
  )
}

export function Stepper({steps, active, setActiveStep=()=>{}, className,diretiva}){
  const App = useContext(AppContext)
  const icons = App.icons

return(<div className={['stepper', className].join(' ')}>
    {steps.map((s,i)=><Fragment key={i}>
      {i>0&&<div className='step-line'></div>}
      {/* <div className={['step-item', i===active?'active':null, !s.validade&&active>i?'error':null, !!s.validate?'done':null, diretiva.includes(i) ? 'disabled' : null].join(' ')} */}
      <div className={diretiva.includes(i) ? 'step-item disabled' : ['step-item',i===active?'active':null, !s.validade&&active>i?'error':null, !!s.validate?'done':null].join(' ')}
        onClick={()=>{!diretiva.includes(i) && setActiveStep(i)}}
      >
        <div className='step-number'>{!s.validate ? i+1 : <icons.BsCheck size={14} />}</div>
        <div className='step-label'>{s.label}</div>
      </div>
    </Fragment>)}
  </div>)
}

export function Dot({size=8, color='var(--color)', blink=null, title=null}){
  return(<div className={['dot', blink?'blink':''].join(' ')} title={title}
    style={{width: size, height: size, background: color, outlineColor: color}} />)
}

export function Slider({value=0, step=1, min=0, max=100, hasLabel=true, onChange=()=>{}, className='', ...rest}){
  const [_value, set_Value] = useState(value),
        [_timeout, set_Timeout] = useState(null)

  function change(e){
    if( e.target.value !== _value ){
      set_Value(e.target.value)
      clearTimeout( _timeout )
      set_Timeout( setTimeout(()=>onChange(e), 1000) )
    }
  }

  return(<div className={['slider', className].join(' ')} {...rest}>
    <input type="range" step={step} min={min} max={max} value={_value} onInput={e=>{change(e)}} className='f1' />
    {hasLabel&&_value}
  </div>)
}

export function SwitchButton({options=[], value=0, onChange=()=>{}}){
  const [_value, setValue] = useState(value)

  useEffect(()=>onChange(_value), [_value])

  return(<div className='switch-button'>
    {options.map((o,i)=>
      <div key={i} className={['switch-button-option',o.value===_value?'selected':''].join(' ')}
        onClick={()=>setValue(o.value)}
      >
        {o.label}
      </div>
    )}
  </div>)
}

export { Checkbox } from './Checkbox'
export { CSVDownload } from './CSVDownload'
export { Frame } from './Frame'
export { IconButton } from './IconButton'
export { Switch } from './Switch'
export * as chart from 'recharts'
export * as Barcode from 'react-barcode'
export { default as Skeleton } from 'react-loading-skeleton'
export { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
