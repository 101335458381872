import React, { useContext, useEffect, useState, useCallback } from "react"
import { AppContext } from '../App'
import * as c from '../c'
import * as f from '../f'

export function EventosModal({ portaria, onClose, onFinish }) {
  const App = useContext(AppContext)
  const lang = { ...App.lang.global, ...App.lang.dfe, ...App.lang.transportador }
  const icons = App.icons;

  const [loading, setLoading] = useState(false)
  const [newObs, setNewObs] = useState({
    descricao: ''
  })
  const [rvObservations, setRvObservations] = useState([])

  async function submit() {
    App.api("controllership::save_event", {
      id_rv: portaria.id.idUocc,
      ds_obs: newObs.descricao,
      event: false
    }).then(r => {
      setNewObs({})
      get()
    })
  }

  const get = useCallback(async () => {
    const { results } = await App.api("controllership::get_event", {
      id_rv: portaria.id.idUocc
    })
    setRvObservations(results);
  }, [portaria?.id?.idUocc])

  useEffect(() => {
    get()
  }, [])

  return (
    <c.Modal
      minHeight={800}
      title={
        'Eventos RV - ' + portaria.id.idUocc
      }
      loading={loading}
      onClose={onClose}
    >
      <div className="f g1 f-column" style={{ width: "70vw",display:'flex',flexDirection:'row', gap: '5px' }}>
        <div style={{ maxWidth: '50%' , width: '100%' }}>
          <c.Chat
            user={App.user.id_usr}
            msg={rvObservations?.filter((obs, i) => obs.EVENT === 'EVENTO') ?? []}
            height={500}
          />
        </div>
        <hr/>
        <div style={{ maxWidth: '50%',width: '100%' }}>
          <c.Chat
            user={App.user.id_usr}
            msg={rvObservations?.filter((obs, i) => obs.EVENT === 'OBS') ?? []}
            height={500}
          />
          <div style={{ display: 'flex', gap: "5px", alignItems: "flex-end" }}>
            <c.Input
              value={newObs.descricao}
              className="f4"
              onChange={(e) => {
                setNewObs({ descricao: e.target.value });
              }}
            />
            <button
              onClick={submit}
              style={{ height: "39px" }}
            >
              <icons.MdSend />
            </button>
          </div>
        </div>
      </div>
    </c.Modal>)
}
