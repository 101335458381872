import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as f from "../../f"

// import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
// import L from "leaflet"

export default function TarifasModal({ onClose, onFinish, itinerario }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.transportadoras,
      ...App.lang.tarifas
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [tarifas, setTarifas] = useState([]),
    [tarifasFilter, setTarifasFilter] = useState(false),
    [tarifasBuca, setTarifasBusca] = useState(""),
    [transportadoras, setTransportadoras] = useState([]),
    [auditoria, setAuditoria] = useState([]),
    [data, setData] = useState({
      DT_INIVLD: App.moment(),
      CD_STS: '1',
      VL_NTRF: 0,
      VL_MAX: itinerario.VL_MAX,
      VL_NMAX: itinerario.VL_NMAX
    }),
    [updateTarifaTeto, setUpdateTarifaTeto] = useState(false),
    [modalMotivo, setModalMotivo] = useState(false),
    [motivo, setMotivo] = useState('');

  function get() {
    setLoading(true)
    //App.api('tarifas::get', {ID_ITINERARIO: itinerario.ID_UOCC}).then(r=>{
    App.api('tarifas::getByTransportadora', { ID_ITINERARIO: itinerario.ID_UOCC }).then(r => {
      setTarifas(r.results);
    })
    setLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  function getNovoValorTeto() {
    setLoading(true)
    App.api('tarifas::getNovoValorTeto', { ID_EXT: itinerario.ID_EXT }).then((r) => {
      { console.log(JSON.stringify(r.results)) }
      setData({
        ...data,
        VL_NMAX: r.results
      })
      { console.log(JSON.stringify(data)) }
      get();
    })

    setLoading(false)
  }

  function getAuditoria() {
    setLoading(true)
    App.api('tarifas::getAuditoria', {
      ID_EXT: itinerario.ID_EXT,
      ID_ITINERARIO: itinerario.ID_UOCC
    }).then((r) => {
      setAuditoria(r.results);
    })
    setLoading(false);
  }

  useEffect(() => {
    getAuditoria()
  }, [updateTarifaTeto]);

  useEffect(() => {
    App.api('tarifas::getTransportadoras').then(r => {
      setTransportadoras(r.results);
    })
  }, [incluir])

  function submit() {
    return App.api("tarifas::save", {
      ...data,
      ID_OBJ: itinerario.ID_UOCC,
      ID_EXT: itinerario.ID_EXT,
      VL_TRF: data.VL_TRF.replace(",", "."),
      DT_INIVLD: App.moment(data.DT_INIVLD).format('YYYY-MM-DD')
    }).then(({ status }) => {
      if (!status) return status

      setIncluir(false);
      get();

      return status;
    });
  }

  function submitNovaTarifaTeto() {
    return App.api("tarifas::novaTarifaTeto", {
      ...data,
      ID_UOCC: itinerario.ID_UOCC,
      ID_EXT: itinerario.ID_EXT
    }).then((r) => {
      setData({ ...data, VL_NTRF: 0 })
      getNovoValorTeto();
      setUpdateTarifaTeto(false);
      if (r.status) {
        App.toast.success('Salvo com sucesso!')
      }
    })
  }

  const onFinishConfig = data.VL_NMAX > 0 ? {} : { onFinish: () => setUpdateTarifaTeto(true) };

  const handleReprovar = () => {
    App.api("tarifas::validarNovaTarifaTeto", {
      ID_UOCC: itinerario.ID_UOCC,
      TP_APR: false,
      DS_OBS: motivo
    }).then(() => {
      setModalMotivo(false);
      setData({ ...data, VL_NMAX: 0 });
      get();
    });
  };

  return (
    <c.Modal contentHeight={250} title={lang.tarifa_itinerario + itinerario.ID_UOCC + ' ( #ref ' + itinerario.ID_EXT + ' )'}
      loading={loading} onClose={onClose}
      okText="Solicitar nova tarifa teto"
      {...onFinishConfig}
    >

      <div className='f g1 w100'>
        <div className='f g1'>
          <c.Span label={lang.tarifa_teto} value={"R$ " + f.formatNumber(data.VL_MAX ?? 0, 2)} />
          <c.Span label={"Solicitada nova tarifa teto"} value={"R$ " + f.formatNumber(data.VL_NMAX ?? 0, 2)} />
          {
            data.VL_NMAX > 0 && App.components?.itinerarios?.aprovarTarifa &&
            <>
              <c.IconButton icon={icons.MdThumbUp} title={lang.aprovar} size={30} style={{ marginRight: '5px', color: "var(--success)" }}
                onClick={() => App.api("tarifas::validarNovaTarifaTeto", {
                  ID_UOCC: itinerario.ID_UOCC,
                  TP_APR: true
                }).then(() => setData({ ...data, VL_MAX: data.VL_NMAX, VL_NMAX: 0}))}
              />
              <c.IconButton icon={icons.MdThumbDown} title={lang.reprovar} style={{ color: "var(--danger)" }} size={30}
                onClick={() => setModalMotivo(true)}
              />
            </>
          }
        </div>
      </div>

      <c.Frame
        autoScroll={false}
        actions={{
          add: () => (setData({ ...data }), setIncluir(true)),
          filter: {
            onClick: () => setTarifasFilter(!tarifasFilter),
            value: tarifasFilter,
          },
        }}
        control={
          <c.Input
            placeholder={lang.busca}
            value={tarifasBuca}
            onChange={(e) => setTarifasBusca(e.target.value)}
          />
        }
      >
        {incluir && (
          <div className="g1 f f-row">
            <c.Select
              className="f1"
              value={data.NR_CNPJTRP ?? 0}
              onChange={(e) => setData({ ...data, NR_CNPJTRP: e.value })}
              label={lang.transportadora}
              options={transportadoras}
              required searchable
            />
            <c.Input
              className="f3"
              value={data.VL_TRF ?? ""}
              onChange={(e) => setData({ ...data, VL_TRF: e.target.value })}
              label={lang.valor}
              type="number"
              required
            />
            <c.Input
              required
              label={lang.inicio}
              onChange={(e) => setData({ ...data, DT_INIVLD: App.moment(e.target.value, "YYYY-MM-DD") })}
              type="Date"
              value={App.moment(data.DT_INIVLD, "DD/MM/YYYY").format("YYYY-MM-DD")}
            />
            <div className="f f-row">
              <c.IconButton
                style={{ fontSize: '20px', color: 'var(--' + ((!!data.NR_CNPJTRP && !!data.VL_TRF && (parseFloat(data.VL_TRF??0) <= parseFloat(data.VL_MAX??0) || parseFloat(data.VL_MAX??0) == 0)) ? 'success' : 'colorAlpha') + ')' }}
                disabled={parseFloat(data.VL_TRF??0) > parseFloat(data.VL_MAX??0) && parseFloat(data.VL_MAX??0) > 0}
                onClick={() => { submit() }}
              >
                <icons.BsCheckSquareFill />
              </c.IconButton>

              <c.IconButton
                style={{ fontSize: '20px', color: 'var(--danger)' }}
                onClick={() => { setIncluir(false); setData({}) }}
              >
                <icons.BsXSquareFill />
              </c.IconButton>
            </div>
          </div>
        )}

        <c.Table
          hasSub={"sub"}
          data={tarifas.map((tp) => ({
            ...tp,
            VALIDACAOTRF: tp.CD_STS === '1' ? <icons.BsCheck2Circle size={16} style={{ color: 'var(--success)' }} /> : tp.CD_STS === '-3' ? <icons.BsXCircle size={16} style={{ color: 'var(--danger)' }} /> : '',
            // VL_TRF: "R$ " + tp.VL_TRF.replace(".", ","),
            VL_TRF: `R$ ${parseFloat(tp.VL_TRF).toFixed(2).replace(".", ",")}`,
            actions: App.components?.itinerarios?.aprovarTarifa && tp.CD_STS === '13' && (
              <>
                <c.IconButton icon={icons.MdThumbUp} title={lang.aprovar} size={30} style={{ marginRight: '5px', color: "var(--success)" }}
                  onClick={() => App.api("tarifas::alterarSts", { ID_UOCC: tp.ID_UOCC, CD_STS: '1' }).then(() => get())}
                />
                <c.IconButton icon={icons.MdThumbDown} title={lang.reprovar} style={{ color: "var(--danger)" }} size={30}
                  onClick={() => App.api("tarifas::alterarSts", { ID_UOCC: tp.ID_UOCC, CD_STS: '-3' }).then(() => get())} />
              </>
            ),
            ValidaTRF: tp.CD_STS === '13' && <c.Dot color="var(--danger)" blink />,
            sub: (
              <c.Table
                isSub
                pagination="no-pagination"
                navigate={false}
                disableLine={'disable'}
                data={tp.LST_TRF.map((trf) => ({
                  VL_TRF: `R$ ${parseFloat(trf.VL_TRF).toFixed(2).replace(".", ",")}`,
                  DT_INIVLD: trf.DT_INIVLD,
                  NM_USR: trf.NM_USR,
                  disable: trf.CD_STS === '-3'
                }))}
                columns={[
                  ["Valor(R$)", "VL_TRF", {}, "f center"],
                  ["Data Inicio", "DT_INIVLD", {}, "f center"],
                  ["Usuário", "NM_USR", {}, "f center"],
                ]}
              />
            )
          }))}
          columns={[
            //['', 'VALIDACAOTRF', {}, "f center"],
            //["#", "ID_UOCC", {}, "f center"],
            ['', 'ValidaTRF'],
            [lang.cnpj_transportadora, "NR_CNPJTRP", {}, "f center"],
            [lang.nome_transportadora, "NM_PESTRP", {}, "f center"],
            [lang.valor, "VL_TRF", {}, "f center"],
            [lang.inicio, "DT_INIVLD", {}, "f center"],
            //[lang.acao, "actions", {}, "f center"],
          ]}
          search={tarifasBuca}
          showFilters={tarifasFilter}
          style={{ overflowY: "auto", width: "70vw", maxHeight: "70vh" }}
        />
      </c.Frame>

      {
        !!updateTarifaTeto &&
        <c.Modal title={'Solicitação de nova tarifa teto' + ' ( #ref ' + itinerario.ID_EXT + ' )'}
          onClose={() => (setUpdateTarifaTeto(false), setData({ ...data, VL_NTRF: 0 }))}
          onFinish={submitNovaTarifaTeto}
          validate={true}
        >
          <div className='f g1 w100'>
            <div className='f g1'>
              <c.Span label={'Tarifa teto atual'} value={"R$ " + f.formatNumber(data.VL_MAX ?? 0, 2)} />
            </div>
          </div>
          <div className="w100">
            <c.Frame>
              <c.Input className="f3" label={lang.valor} value={f.formatNumber(data.VL_NTRF??0, 2)} onChange={e=>setData({...data, VL_NTRF: f.toFloat(e.target.value, 2)})} required />
            </c.Frame>
          </div>
        </c.Modal>
      }

      {
        !!modalMotivo &&
        <c.Modal title={lang.motivo} onClose={()=>setModalMotivo(false)}
          validate={motivo.length >= 10} onFinish={handleReprovar}
          okText={'Confirmar'}
        >
          <c.Input label={lang.motivo} value={motivo} onChange={e => setMotivo(e.target.value)} error={motivo.length < 10}
            info={'Digite ao menos 10 letras.'} />
        </c.Modal>
      }

    </c.Modal >
  );
}

